import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SlideShow from '../common/Slideshow';
import Logo from '../common/Logo';

import './quickquoteload.scss';

import queryString from 'query-string';
import axios from 'axios';

function QuickQuoteLoad() {
	// const dispatch = useDispatch();
	const { search } = useLocation();
	const values = queryString.parse(search);

	/*
	Expencted Values
	email = agent email
	platform_id
	quote_ref = quick quote ID
	req_agency_id
	*/
	// console.log(values); // "top"
	// https://quickquote.beyondfloods.com/quickquoteload/?quote_ref=QUOTEREF&platform_id=PLATFORM_ID&req_agency_id=AGENTBFID&email=AGENTEMAIL

	const [loading, setLoading] = useState(true);
	const [quoteRef, setQuoteRef] = useState('');
	const [customerName, setCustomerName] = useState('');
	const [customerAddress, setCustomerAddress] = useState('');

	const fetchQuickQuoteLoad = async () => {
		try {
			let response = await axios.post('/api/quickquote-load', { params: values });
			setLoading(false);
			const quoteInfo = response.data.bf_quote_info[0];
			const quickQuoteLoadData = response.data.bfquickquoteload_data;
			setQuoteRef(quoteInfo.platform_quote_id);
			setCustomerName(quickQuoteLoadData.applicant);
			const address = quickQuoteLoadData.address + ', ' + quickQuoteLoadData.city + ', ' + quickQuoteLoadData.state + ', ' + quickQuoteLoadData.zip;
			setCustomerAddress(address);

			const emailBody = {
				quote_reference: quoteInfo.platform_quote_id,
				customer_name: quickQuoteLoadData.applicant,
				customer_address: address,
				agent_email: values.email,
			};

			await axios.post('/api/email/qql', { params: emailBody });
		} catch (error) {
			console.error(error.message);
		}
	};

	useEffect(() => {
		if (loading === true) fetchQuickQuoteLoad();
	}, []); // eslint-disable-line

	return (
		<div className='bf-quoteref'>
			<Logo />
			{loading === false ? (
				<div className='bf-quoteref-wrapper'>
					<h1>Quote Successfully Uploaded</h1>
					<h3>
						Quote Reference: <strong>{quoteRef}</strong>
					</h3>
					<h3>
						Customer Name: <strong>{customerName}</strong>
					</h3>
					<h3>
						Customer Address: <strong>{customerAddress}</strong>
					</h3>
					<p>
						<a href='https://natgenagency.com/' target='_blank' rel='noreferrer'>
							Click Here
						</a>{' '}
						to Log in to your Nat Gen Agency account, navigate to Beyond Floods via the flood center to retrieve the quote and proceed to next steps
					</p>
				</div>
			) : (
				<SlideShow />
			)}
		</div>
	);
}

export default QuickQuoteLoad;
