import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { fetchRouteLead } from '../../actions/routeLead';
import { fetchQuickQuote } from '../../actions/quote';
import { logLeadRecord } from '../../actions/leadRecord';
import { gaEvent } from '../../actions/ganalytics';
import {
	setQuickQuoteID,
	setQuoteSet,
	setStatusList,
	setApplicant,
	setApplicantEmail,
	setApplicantPhone,
	setRouteLeadInfo,
	setFloodZone,
	setFloodElevation,
	setApplReason,
	setTimestamp,
	setQuotes,
	setBfScore,
	setBfScoreCat,
	setPlatformId,
} from '../../reducers/inputs';
import { renderMeter, formatPhoneNumber, setFoundationType } from '../../actions/helpers';
import LoadingSpinner from '../common/loadingSpinner';
import Unavailable from '../unavailable/Unavailable';
import MarketingComDisclaimer from '../common/MarketingComDisclaimer';
import Logo from '../common/Logo';

const Outlook = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentState = useSelector((state) => state);
	const [quoteFetched, setQuoteFetched] = useState(false);
	const [outlookData, setOutlookData] = useState({});
	const [riskFactors, setRiskFactors] = useState(0);
	const [availability, setAvailability] = useState(true);
	const [displayForm, setDisplayForm] = useState(true);
	const [availabilityReason, setAvailabilityReason] = useState('');
	const [routeLead, setRouteLead] = useState();
	const { register, handleSubmit, control } = useForm();
	const [showLoadSpin, setShowLoadSpin] = useState(true);

	if (!currentState.address) {
		history.push('./');
	} else if (!currentState.building_q) {
		history.push('./building');
	} else if (!currentState.foundation_q) {
		history.push('./foundation');
	} else if (!currentState.constr_frame) {
		history.push('./constr-frame');
	} else if (!currentState.rep_cost || !currentState.year_built || !currentState.occupancy_q || !currentState.num_floors) {
		history.push('./more-info');
	}

	//es-lint-disable-next-line
	const fetchOutlook = async (quoteFetched) => {
		if (!quoteFetched) {
			setQuoteFetched(true);
			try {
				let response = await axios.post('/api/outlook', { params: currentState });
				const outlookData = response.data;
				const statusCode = outlookData.bfoutlookreport_status_list[0].bfoutlookreport_status;

				if (statusCode === 'OR01' || statusCode === 'OR02' || statusCode === 'OR03') {
					setOutlookData(outlookData);
					dispatch(setFloodZone(outlookData.zone_eff));
					dispatch(setFloodElevation(outlookData.bfe_eff));
					dispatch(setBfScore(outlookData.bf_score));
					dispatch(setBfScoreCat(outlookData.bf_score_cat));
					let riskCount = 0;
					if (outlookData.f_500yrdpth) {
						riskCount += 1;
					}
					if (outlookData.f_femadist_eff) {
						riskCount += 1;
					}
					if (outlookData.f_femadist_prel) {
						riskCount += 1;
					}
					if (outlookData.f_femareg_eff) {
						riskCount += 1;
					}
					if (outlookData.f_femareg_prel) {
						riskCount += 1;
					}
					if (outlookData.f_hurrcat) {
						riskCount += 1;
					}
					if (outlookData.f_lowscore) {
						riskCount += 1;
					}
					if (outlookData.f_minfreq) {
						riskCount += 1;
					}
					if (outlookData.f_nearloss) {
						riskCount += 1;
					}
					if (outlookData.f_nearpols) {
						riskCount += 1;
					}
					if (outlookData.f_slr) {
						riskCount += 1;
					}
					if (outlookData.f_twi) {
						riskCount += 1;
					}
					setRiskFactors(riskCount);

					gaEvent({
						action: 'outlook_report_generated',
						category: 'engagement',
						label: 'Outlook Report Successfully Fetched',
					});
				} else {
					const routeLeadFetch = await fetchRouteLead({
						leadref_id: currentState.leadref_id,
						address: currentState.address,
						city: currentState.city,
						state: currentState.state,
						zip: currentState.zip,
					});

					const routeLeadData = routeLeadFetch.data;

					gaEvent({
						action: 'kickout_outlook',
						category: 'kickout_event',
						label: 'Kickout at Outlook Report Fetch',
					});
					dispatch(setRouteLeadInfo(routeLeadData));
					setRouteLead(routeLeadData.routelead_info);
					setAvailabilityReason('outlook');
					setAvailability(false);
				}
				setShowLoadSpin(false);
			} catch (error) {
				console.error(error.message);
			}
		}
	};

	useEffect(() => {
		fetchOutlook(quoteFetched);
	});

	const onSubmit = async (data) => {
		setShowLoadSpin(true);
		window.scrollTo(0, 0);
		let timestamp = new Date(outlookData.bfoutlookreport_timestamp);

		let reasonCount;
		if (data.reason.length > 1) {
			reasonCount = true;
		} else {
			reasonCount = false;
		}
		let refBody = {
			...currentState,
			applicant: data.client_name,
			appl_phone: formatPhoneNumber(data.client_phone),
			appl_email: data.client_email,
			timestamp: timestamp,
			appl_reason_count: reasonCount,
			appl_reason: data.reason,
		};

		dispatch(setApplReason(data.reason));
		dispatch(setTimestamp(timestamp));
		dispatch(setApplicant(data.client_name));
		dispatch(setApplicantEmail(data.client_email));
		dispatch(setApplicantPhone(data.client_phone));

		gaEvent({
			action: 'quote_form_filled',
			category: 'engagement',
			label: 'Client Filled Out Quote Form',
		});

		try {
			const quoteReturn = await fetchQuickQuote(refBody);

			dispatch(setQuickQuoteID(quoteReturn.quickquote_id));
			dispatch(setStatusList(quoteReturn.bfquickquote_status_list));

			const quotes = quoteReturn.quote_set;
			const statusCode = quoteReturn.bfquickquote_status_list[0].bfquickquote_status;
			if (statusCode === 'Q001' || statusCode === 'Q002' || statusCode === 'Q003') {
				dispatch(setQuoteSet(quotes));
				dispatch(setQuotes(quotes));

				refBody = {
					...refBody,
					quickquote_id: quoteReturn.quickquote_id,
				};

				const routeLeadFetch = await fetchRouteLead(refBody);

				const routeLeadData = routeLeadFetch.data;
				const routeLeadInfo = routeLeadData.routelead_info;

				dispatch(setRouteLeadInfo(routeLeadData));

				refBody = {
					...refBody,
					rep_cost: refBody.rep_cost,
					foundation_q: setFoundationType(refBody.foundation_q),
					quote_set: quotes,
					bfquickquote_status_list: quoteReturn.bfquickquote_status_list,
					quotes: {
						essential: {
							...quotes[0],
						},
						enhanced: {
							...quotes[1],
						},
						elite: {
							...quotes[2],
						},
					},
					outlook_data: {
						...outlookData,
						riskImage: renderMeter(outlookData.bf_score_cat),
					},
					bf_score: outlookData.bf_score,
					bf_score_cat: outlookData.bf_score_cat,
					quickquote_id: quoteReturn.quickquote_id,
					routelead_info: {
						...routeLeadInfo,
						agent_contactphone: routeLeadInfo.agent_contactphone,
						lead_email_type: routeLeadData.lead_email_type,
						lead_email_inbox: routeLeadData.lead_email_inbox,
						lead_source: routeLeadData.lead_source,
						lead_crmload: routeLeadData.lead_crmload,
					},
				};

				const leadRecordReturn = await logLeadRecord(refBody, 'low_potential_lead');

				dispatch(setPlatformId(leadRecordReturn.platform_quote_id));

				gaEvent({
					action: 'quote_fetch_success',
					category: 'low_potential_lead',
					label: 'Quote Fetched Successfully',
				});

				history.push('./result');
			} else {
				const routeLeadFetch = await fetchRouteLead({
					quickquote_id: quoteReturn.quickquote_id,
					leadref_id: currentState.leadref_id,
					address: currentState.address,
					city: currentState.city,
					state: currentState.state,
					zip: currentState.zip,
					geoc_lat: outlookData.geoc_lat,
					geoc_lon: outlookData.geoc_lon,
				});
				const routeLeadData = routeLeadFetch.data;
				gaEvent({
					action: 'kickout_quote',
					category: 'kickout_event',
					label: 'Kickout at Quote Fetch',
				});
				dispatch(setRouteLeadInfo(routeLeadData));
				setRouteLead(routeLeadData.routelead_info);
				setDisplayForm(false);
				setAvailabilityReason('quote');
				setAvailability(false);
				setShowLoadSpin(false);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className='bf-contents'>
			{showLoadSpin && <LoadingSpinner />}
			<div className='bf-outlook-container' {...showLoadSpin && {hidden: true}}>
				
				<div className='bf-header' >
					<Logo />
					<p className='bf-title-address'>
						<i className='fas fa-map-marked'></i>
						{currentState.address}, {currentState.city}, {currentState.state} {currentState.zip}
					</p>
				</div>

				{!availability && <Unavailable reason={availabilityReason} contact={routeLead} form={displayForm} />}

				{outlookData.bf_score_cat && availability && (
					<div className='bf-outlook-wrapper'>
						<h2 className='bf-title'>Personalized Flood Outlook Report Summary</h2>
						<div className='bf-map-container'>
							<img src={outlookData.map_link} alt='Google map of property location' />
						</div>
						<div className='bf-risk-score'>
							<h3 className={outlookData.bf_score_cat.replace(' ', '-').toLowerCase()}>{outlookData.bf_score}</h3>
							<h5 className={outlookData.bf_score_cat.replace(' ', '-').toLowerCase()}>
								<b>{outlookData.bf_score_cat}</b>
								<span>Flood Outlook Score</span>
							</h5>
							<div className='bf-score-meter'>
								<img src={renderMeter(outlookData.bf_score_cat)} alt='Flood risk score meter' />
							</div>
						</div>
						<div className='bf-flood-issues'>
							<p>
								Your Property is affected by <span>{riskFactors}</span> of the 12 top risk factors we analyze.
							</p>
							<h5>To request a FREE Flood Report with these risk factors and see your custom flood estimate, please enter your information below:</h5>
							<form className='bf-outlook-data bf-form' onSubmit={handleSubmit(onSubmit)}>
								<div className='row'>
									<div className='col l4 s12'>
										<div className='bf-input-wrapper'>
											<i className='fas fa-user-circle'></i>
											<Controller
												as={<input />}
												control={control}
												required
												type='text'
												id='client_name'
												name='client_name'
												placeholder='Enter Your Name'
												defaultValue={currentState.applicant}
											/>
										</div>
									</div>
									<div className='col l4 s12'>
										<div className='bf-input-wrapper'>
											<i className='far fa-envelope'></i>
											<Controller
												as={<input />}
												control={control}
												required
												type='email'
												id='client_email'
												name='client_email'
												placeholder='Enter Your Email'
												defaultValue={currentState.appl_email}
											/>
										</div>
									</div>
									<div className='col l4 s12'>
										<div className='bf-input-wrapper'>
											<i className='fas fa-phone-alt'></i>
											<Controller
												required
												defaultValue={currentState.appl_phone}
												name='client_phone'
												id='client_phone'
												control={control}
												as={<NumberFormat format='(###) ###-####' mask='_' />}
												placeholder='Enter Your Phone Number'
											/>
										</div>
									</div>
									<div className='col s12'>
										<fieldset>
											<legend>
												Why are you requesting this quote?<span>Select all that apply</span>
											</legend>
											<label htmlFor='I am a homeowner'>
												<input name='reason' type='checkbox' value='I am a homeowner' ref={register} />I am a homeowner
											</label>
											<label htmlFor='I am buying a new home'>
												<input name='reason' type='checkbox' value='I am buying a new home' ref={register} />I am buying a new home
											</label>
											<label htmlFor='I am a Real Estate agent getting a quote for my customer'>
												<input name='reason' type='checkbox' value='I am a Real Estate agent getting a quote for my customer' ref={register} />I am a Real Estate agent getting a quote for my
												customer
											</label>
											<label htmlFor='My lender requires flood insurance'>
												<input name='reason' type='checkbox' value='My lender requires flood insurance' ref={register} />
												My lender requires flood insurance
											</label>
											<label htmlFor='I am just browsing'>
												<input name='reason' type='checkbox' value='I am just browsing' ref={register} />I am just browsing
											</label>
										</fieldset>
									</div>
								</div>
								<MarketingComDisclaimer button_name="Proceed to Quote"/>
								<div className='bf-button-wrapper'>
									<button>Proceed to Quote</button>
								</div>
							</form>
						</div>
						<div className='bf-disclaimer-wrapper'>
							<p>
								*DISCLAIMER: The information in the Flood Outlook Report Summary is provided for informational purposes only. All such information on this site is presented without any
								representation, guaranty, or warranty whatsoever regarding the accuracy, relevance, or completeness of the information. policy premium estimates contained in this website is for
								illustrative purposes only and coverage under any private flood insurance policy is expressly subject to the conditions, restrictions, limitations, exclusions and terms of the
								policy documentation issued by the insurer. Availability of this program is subject to each state' s approval and coverage may vary by state. Private flood insurance policies are
								underwritten by Direct General Insurance Company, National General Premier Insurance Company, Agent Alliance Insurance Company or other wholly owned AM Best A- Excellent Rated
								subsidiaries of National General Insurance Company. This product is not affiliated with the National Flood insurance Program.{' '}
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Outlook;
