import UnavailableForm from "./UnavailableForm";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { logLeadRecord } from "../../actions/leadRecord";
import { reasonDescription } from "../../actions/helpers";
import AgentDetails from '../common/AgentDetails';

/**
 * The unfavialable functional component is used to display the unavaialble page. There are options to display the form as well as whether or not to display the agent's contact infomration based on the routelead information
 * @param {object} props - The props object contains the contact information for the routelead as well as the reason for an unavaialble kickout. There is also a boolean under props.form for whether or not to display the form on the unavailable page
 * @returns Unavailable Page
 */
function Unavailable(props) {
  const [showContact, setShowContact] = useState(false);
  const currentState = useSelector((state) => state);

  useEffect(() => {
    /**
     * logging the kickout event when the unavaialble funcitonal component is loaded
     */
    const leadRecordLog = async () => {
      await logLeadRecord(currentState, "kickout_" + props.reason);
    };
    leadRecordLog();
    // Setting the show contact based on the props passed in. Will trigger whether the agent's contact info is displayed or not below
    setShowContact(props.contact.show_agent_info);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contact.show_agent_info, props.reason]);

  /**
   * Function to send them back to the homepage and clear the reducer if they click the start over button
   */
  const refreshPage = () => {
    // var currentPage = window.location.pathname;
    window.location.replace(window.location.origin);
    // window.location.reload();
  };

  return (
    <div className="bf-container">
      {showContact && (
        <div className="bf-unavailable">
          <h1 className="bf-title">Thank you for quoting with Beyond Floods</h1>
          <div className="row">
            <div className="col m6 s12 left-align center-align-on-med-and-down">
              <p className="bf-p">
                {reasonDescription(props.reason)}{" "}
                {props.form && (
                  <span>
                    <br />
                    <br />
                    At this time, Beyond Floods is unable to cover the described
                    property type. Please complete this form and one of our
                    flood specialists will contact you to discuss alternate
                    flood insurance options.
                  </span>
                )}
                {!props.form && (
                  <span>
                    A representative will be in touch shortly to discuss your
                    options.
                  </span>
                )}
              </p>
            </div>
            <div className="col m6 s12 right-align center-align-on-med-and-down">
              <AgentDetails routelead_info={currentState.routelead_info} />
            </div>
          </div>
          {props.form && <UnavailableForm reason={props.reason} />}
          <div className="bf-button-wrapper">
            <button className="bf-back" onClick={refreshPage}>
              <i className="fas fa-angle-left"></i> Start Over
            </button>
          </div>
        </div>
      )}
      {!showContact && (
        <div className="bf-unavailable">
          <h1 className="bf-title">
            Thank you for quoting with Beyond Floods!
          </h1>
          <p className="bf-p">
            {reasonDescription(props.reason)}{" "}
            {props.form && (
              <span>
                At this time, Beyond Floods is unable to cover the described
                property type. Please fill out the form below for additional
                information on Beyond Floods and flood insurance options.
              </span>
            )}
            {!props.form && (
              <span>
                A representative will be in touch shortly to discuss your
                options.
              </span>
            )}
          </p>
          {props.form && <UnavailableForm reason={props.reason} />}
          <div className="bf-button-wrapper">
            <button className="bf-back" onClick={refreshPage}>
              <i className="fas fa-angle-left"></i> Start Over
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Unavailable;
