import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchQuickQuoteInfo } from '../../actions/quote';
import { useDispatch } from 'react-redux';
import {
	chooseAddress,
	chooseCity,
	chooseState,
	chooseZip,
	chooseFoundation,
	chooseBuilding,
	setStories,
	chooseFrame,
	setYear,
	chooseOccupancy,
	setReplacementCost,
	setCoverageA,
	setCoverageC,
	setApplicant,
	setApplicantEmail,
	setPreloadId,
	setLeadRefId,
	setApplicantPhone,
} from '../../reducers/inputs';

import queryString from 'query-string';

const QuoteRef = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();

	/*
	Expected values
	?quote_ref = quick quote ID
	?platform_id = platform ID
	http://localhost:3000/quickquoteref/?quote_ref=syijc4rwj&platform_id=7if3yb
	*/
	const values = queryString.parse(search);

	const storeQuickQuoteInfo = async () => {
		try {
			let quickQuote = await fetchQuickQuoteInfo(values);

			let quoteInfo = quickQuote.data.bfquickquoteinfo_data;

			dispatch(setLeadRefId(quoteInfo.leadref_id));
			dispatch(setPreloadId(quoteInfo.preloadinfo_id));
			dispatch(chooseAddress(quoteInfo.address));
			dispatch(chooseCity(quoteInfo.city));
			dispatch(chooseState(quoteInfo.state));
			dispatch(chooseZip(quoteInfo.zip));
			dispatch(chooseBuilding('single_family'));
			dispatch(chooseFoundation(quoteInfo.foundation_q));
			dispatch(setStories(quoteInfo.num_floors));
			dispatch(chooseFrame(quoteInfo.constr_frame));
			dispatch(setYear(quoteInfo.year_built));
			dispatch(chooseOccupancy(quoteInfo.occupancy_q));
			dispatch(setReplacementCost(quoteInfo.rep_cost));
			dispatch(setCoverageA(quoteInfo.user_cov_a_bldg));
			dispatch(setCoverageC(quoteInfo.user_cov_c_cont));
			dispatch(setApplicant(quoteInfo.applicant));
			dispatch(setApplicantEmail(quoteInfo.appl_email));
			dispatch(setApplicantPhone(quoteInfo.appl_phone));

			// return;

			setTimeout(() => {
				history.push('/foundation');
			}, 400);
		} catch (error) {
			console.error(error.message);
		}

	};

	useEffect(() => {
		storeQuickQuoteInfo();
	});

	return <div className='bf-quoteref'></div>;
};

export default QuoteRef;
