import loadingGif from '../../assets/house-loading.gif';
import FloodPlaceholder from './floodPlaceholder';

function LoadingSpinner() {
	const factList = [
		"Most Beyond Floods customers can save 30% or more compared to the NFIP.",
		"Beyond Floods is fully lender compliant and guaranteed to meet the statutory definition of private flood insurance.",
		"Beyond Floods is an Allstate product. Allstate has an AM Best A+ Superior rating.",
		"Get your FREE Flood Outlook Report, tailored to your individual property.",
		"One-size doesn’t fit all - Pick from three available coverage tiers.",
		"Beyond Floods offers dwelling limits up to six times higher than the NFIP.",
		"Choose from flexible payment plans and multiple payment options."
	];
	return (
		<div className='bf-loading-spinner-wrapper'>
			<img src={loadingGif} alt='bird loading spinner' className='bird-spin' />
			<FloodPlaceholder msgList={factList}/>
		</div>
	);
}

export default LoadingSpinner;
