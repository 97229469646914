/**
 * The AgentDetails component is used to generate the HTML snippet used to display agent contact details.
 * @param {object} props - The props object contains information on the routelead.
 * @returns Agent Details HTML snippet
 */

export default function AgentDetails(props) {

    return (
        <div className="bf-contact-wrapper">
            <p className="bf-contact">
                In the meantime, you may also contact our agent at:
            </p>
            {/* Contact Name */}
            {props.routelead_info.agent_contactname &&
                <span className="bf-contact-info">
                    <i className="far fa-user"></i>{" "}
                    {props.routelead_info.agent_contactname}
                </span>
            }

            {/* Contact Email */}
            {props.routelead_info.agent_contactemail &&
                <span className="bf-contact-info">
                    <i className="far fa-envelope"></i>
                    <a href={`mailto:${props.routelead_info.agent_contactemail}`}>
                        {props.routelead_info.agent_contactemail}
                    </a>
                </span>
            }

            {/* Contact Agency Name */}
            {props.routelead_info.agent_agencyname &&
                <span className="bf-contact-info">
                    {props.routelead_info.agent_agencyname}
                </span>
            }

            {/* Contact Location */}
            {props.routelead_info.agent_location &&
                <span className="bf-contact-info">
                    <i className="fas fa-map-marker-alt"></i>{" "}
                    {props.routelead_info.agent_location}
                </span>
            }

            {/* Contact Phone */}
            {props.routelead_info.agent_contactphone &&
                <span className="bf-contact-info">
                    <i className="fas fa-phone"></i>
                    <a href={`tel:${props.routelead_info.agent_contactphone}`}>
                        {props.routelead_info.agent_contactphone}
                    </a>
                </span>
            }
        </div>
    )
}
