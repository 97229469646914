import ReactGA from 'react-ga4';

/**
 * Uses React GA to create Google Analytics events. Current Events are:
 * Kickout {action: Kickout, category: engagement, label: kickout_ + kickout stage}
 * @param {object} params object containing any number of the 5 values needed to create a Google Analytics event
 */
export const gaEvent = (params) => {
	ReactGA.event({
		action: params.action,
		category: params.category,
		label: params.label,
		value: params.value,
		nonInteraction: params.nonInteraction,
	});
};

export const gaCampaign = (params) => {
	const ga = ReactGA.ga();
	ga('set', 'campaignId', params);
	ga('set', 'campaignName', params);
	ga('set', 'campaignMedium', 'LeadRef Link');
};
