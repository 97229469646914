import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMortgageRelated, setCurrentIns, setEffectiveDate } from '../../reducers/inputs';
import { logLeadRecord } from '../../actions/leadRecord';
import Logo from '../common/Logo';
import { format } from 'fecha';
import { gaEvent } from '../../actions/ganalytics';
import { formatPhoneNumber, setFoundationType } from '../../actions/helpers';

import 'react-datepicker/dist/react-datepicker.css';

const BindNow = () => {
	const [startDate, setStartDate] = useState(null);

	// eslint-disable-next-line
	const dispatch = useDispatch();
	const history = useHistory();
	const currentState = useSelector((state) => state);

	const { register, handleSubmit, control } = useForm();

	if (!currentState.address) {
		history.push('./');
	} else if (!currentState.building_q) {
		history.push('./building');
	} else if (!currentState.foundation_q) {
		history.push('./foundation');
	} else if (!currentState.constr_frame) {
		history.push('./constr-frame');
	} else if (!currentState.rep_cost || !currentState.year_built || !currentState.occupancy_q || !currentState.num_floors) {
		history.push('./more-info');
	} else if (!currentState.quote_set) {
		history.push('./outlook');
	} else if (!currentState.selected_tier) {
		history.push('./result');
	}

	const onSubmit = async (data) => {
		dispatch(setMortgageRelated(data.mortgage_related));
		dispatch(setCurrentIns(data.current_flood_ins));
		dispatch(setEffectiveDate(format(startDate, 'YYYY-MM-DD')));

		let refBody = {
			...currentState,
			mortgage_related: data.mortgage_related,
			current_flood_ins: data.current_flood_ins,
			eff_date_req: format(startDate, 'YYYY-MM-DD'),
			essential_selected: false,
			enhanced_selected: false,
			elite_selected: false,
			rep_cost: currentState.rep_cost,
			foundation_q: setFoundationType(currentState.foundation_q),
			routelead_info: {
				...currentState.routelead_info,
				agent_contactphone: formatPhoneNumber(currentState.routelead_info.agent_contactphone),
			},
		};

		if (currentState.selected_tier === 'Essential') {
			refBody = {
				...refBody,
				essential_selected: true,
			};
		} else if (currentState.selected_tier === 'Enhanced') {
			refBody = {
				...refBody,
				enhanced_selected: true,
			};
		} else if (currentState.selected_tier === 'Elite') {
			refBody = {
				...refBody,
				elite_selected: true,
			};
		}

		try {
			logLeadRecord(refBody, 'high_potential_lead');

			gaEvent({
				action: 'bind_form_filled',
				category: 'high_potential_lead',
				label: 'Client Filled Out Bind Form',
			});
			history.push('./thank-you');
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className='bf-bind-now'>
			<div className='bf-header'>
				<Logo />
				<p className='bf-title-address'>
					<i className='fas fa-map-marked'></i>
					{currentState.address}, {currentState.city}, {currentState.state} {currentState.zip}
				</p>
			</div>
			<div className='row'>
				<div className='col l5 s12'>
					<div className='bf-quote-wrapper'>
						{currentState.quote_set.map((item, index) => {
							let bestFit;
							let quoteTier = item.quote_tier;
							if (currentState.selected_tier === quoteTier) {
								return (
									<div key={index} id={`bf-${quoteTier.toLowerCase()}`} className={`bf-quote-block bf-${item.quote_tier.toLowerCase()} ${bestFit}`}>
										<div className='bf-quote-header'>
											<div className='bf-best-fit-banner'>
												<h4>Selected</h4>
											</div>
											<h4>{item.quote_tier}</h4>
											<h5>${item.tier_fees.premium_incl_fees_taxes.toFixed(2)} **</h5>
											<p>{item.quote_desc}</p>
										</div>
										<div className='bf-quote-body'>
											{/* <p className='bf-warn'>Quotes shown are subject to referral prior to binding</p> */}
											<div>
												<strong>
													<NumberFormat value={item.deductible} displayType={'text'} thousandSeparator={true} prefix={'$'} />
												</strong>
												<span>Deductible</span>
											</div>
											<div>
												<strong>
													<NumberFormat value={item.cov_a_bldg} displayType={'text'} thousandSeparator={true} prefix={'$'} />
												</strong>
												<span>Dwelling Limit</span>
											</div>
											<div>
												<strong>
													<NumberFormat value={item.cov_b_other_bldg} displayType={'text'} thousandSeparator={true} prefix={'$'} />
												</strong>
												<span>Other Building Limit</span>
											</div>
											<div>
												<strong>
													<NumberFormat value={item.cov_c_cont} displayType={'text'} thousandSeparator={true} prefix={'$'} />
												</strong>
												<span>Content Limit</span>
											</div>
											<div>
												<strong>
													<NumberFormat value={item.cov_f_lossofuse} displayType={'text'} thousandSeparator={true} prefix={'$'} />
												</strong>
												<span>Loss of Use</span>
											</div>
											<div>
												<strong>
													<NumberFormat value={item.cov_c_special} displayType={'text'} thousandSeparator={true} prefix={'$'} />
												</strong>
												<span>Special Limits Cap</span>
											</div>
											<div>
												<strong>
													<NumberFormat value={item.cov_e_icc} displayType={'text'} thousandSeparator={true} prefix={'$'} />
												</strong>
												<span>Increased Cost of Compliance</span>
											</div>
											<div className='bf-extras'>
												{item.cov_d_debris > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_debris} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														Debris Removal
													</div>
												)}
												{item.cov_d_lossavoid > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_lossavoid} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														Loss Avoidance Measures
													</div>
												)}
												{item.cov_d_propremove > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_propremove} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														Property Removed to Safety
													</div>
												)}
												{item.cov_d_lossassess > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_lossassess} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														Loss Assessment
													</div>
												)}
												{item.cov_d_firedeptserv > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_firedeptserv} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														Fire Dept. Service Charge
													</div>
												)}
												{item.cov_d_deckramp > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_deckramp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														Deck & Handicap Ramp Coverage
													</div>
												)}
												{item.cov_d_laword > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_laword} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														of Dwelling Limit - Law Ordinance Endorsement
													</div>
												)}
												{item.cov_d_pool > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_pool} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														Pool Clean-up / Repair
													</div>
												)}
												{item.cov_d_trees > 0 && (
													<div>
														<strong>
															<NumberFormat value={item.cov_d_trees} displayType={'text'} thousandSeparator={true} prefix={'$'} />
														</strong>
														Trees, Shrubs & Other Plants
													</div>
												)}
												<p>Other Coverages</p>
											</div>
										</div>
									</div>
								);
							} else {
								return <div key={index}></div>;
							}
						})}
					</div>
				</div>
				<div className='col l7 s12'>
					<form onSubmit={handleSubmit(onSubmit)} className='bf-bind-form'>
						<h2 className='bf-title'>PLEASE PROVIDE  A FEW MORE DETAILS ABOUT YOUR PROPERTY:</h2>
						<section className='bf-input'>
							<h3>Effective Date Desired</h3>
							<DatePicker
								selected={startDate}
								placeholderText='Select desired effective date'
								onChange={(date) => setStartDate(date)}
								ref={register}
								name='effective_date'
								control={control}
								minDate={new Date()}
							/>
						</section>
						<section className='bf-input bf-radio'>
							<h3>Is this application related to a mortgage or real estate closing?</h3>
							<input type='radio' id='control_1' name='mortgage_related' value='Mortgage Closing' ref={register} control={control} />
							<label htmlFor='control_1'>
								<h4>Mortgage Closing</h4>
							</label>
							<input type='radio' id='control_2' name='mortgage_related' value='Other Real Estate Closing' ref={register} control={control} />
							<label htmlFor='control_2'>
								<h4>Other Real Estate Closing</h4>
							</label>
							<input type='radio' id='control_3' name='mortgage_related' value='Not Related to a Closing' ref={register} control={control} />
							<label htmlFor='control_3'>
								<h4>Not Related to a Closing</h4>
							</label>
						</section>
						<section className='bf-input bf-radio'>
							<h3>Do you currently have flood insurance?</h3>
							<input type='radio' id='control_4' name='current_flood_ins' value='Yes, NFIP' ref={register} control={control} />
							<label htmlFor='control_4'>
								<h4>Yes, NFIP</h4>
							</label>
							<input type='radio' id='control_5' name='current_flood_ins' value='Yes, Non-NFIP' ref={register} control={control} />
							<label htmlFor='control_5'>
								<h4>Yes, Non-NFIP</h4>
							</label>
							<input type='radio' id='control_6' name='current_flood_ins' value='No' ref={register} control={control} />
							<label htmlFor='control_6'>
								<h4>No</h4>
							</label>
							<input type='radio' id='control_7' name='current_flood_ins' value='Unsure' ref={register} control={control} />
							<label htmlFor='control_7'>
								<h4>Unsure</h4>
							</label>
						</section>
						<div className='bf-button-wrapper'>
							<button className='bf-next'>Request to Buy</button>
						</div>
					</form>
				</div>
			</div>
			<div className='bf-button-wrapper'>
				<button className='bf-back' onClick={() => history.push('./result')}>
					<i className='fas fa-angle-left'></i> Back
				</button>
			</div>
		</div>
	);
};


export default BindNow;
