import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { chooseFoundation } from '../../reducers/inputs';
import Logo from '../common/Logo';

import slab from '../../assets/slab.png';
import slabHover from '../../assets/slab-hover.png';
import basement from '../../assets/basement.png';
import basementHover from '../../assets/basement-hover.png';
import basement2 from '../../assets/basement2.png';
import basement2Hover from '../../assets/basement2-hover.png';
import basement3 from '../../assets/basement3.png';
import basement3Hover from '../../assets/basement3-hover.png';
import crawlspace from '../../assets/crawlspace.png';
import crawlspaceHover from '../../assets/crawlspace-hover.png';
import piers from '../../assets/piers.png';
import piersHover from '../../assets/piers-hover.png';
import garage from '../../assets/garage.png';
import garageHover from '../../assets/garage-hover.png';
import luckyOrange from '../../actions/luckyOrangeTag';

import axios from 'axios';

import { useCookies } from 'react-cookie';

const FoundationQ = () => {
	// eslint-disable-next-line
	const [cookies, setCookie] = useCookies(['leadref_id']);
	const dispatch = useDispatch();
	const history = useHistory();
	const building = useSelector((state) => state.building_q);
	const foundation = useSelector((state) => state.foundation_q);
	const address = useSelector((state) => state.address);
	const city = useSelector((state) => state.city);
	const state = useSelector((state) => state.state);
	const zip = useSelector((state) => state.zip);
	const { register, setValue } = useForm({ defaultValues: { foundation_q: foundation, foundation_q2: foundation } });

	const [hasBasement, setHasBasement] = useState(false);

	if (cookies.leadref_id) {
		luckyOrange(cookies.leadref_id);
	}

	if (!address) {
		history.push('./');
	} else if (!building) {
		history.push('./building');
	}

	const fetchToken = async () => {
		await axios.get('/api/token');
	};

	const onSubmit = (data) => {
		let foundation_type = data.target.value;

		if (foundation_type === 'basement') {
			setHasBasement(true);
		} else if (foundation_type === 'base_walkout' || foundation_type === 'base_partbelow' || foundation_type === 'base_below') {
			dispatch(chooseFoundation(foundation_type));
			history.push('./constr-frame');
		} else {
			dispatch(chooseFoundation(foundation_type));
			history.push('./constr-frame');
		}
	};

	useEffect(() => {
		fetchToken();
		if (foundation === 'base_walkout' || foundation === 'base_below' || foundation === 'base_partbelow') {
			setValue('foundation_q', 'basement');
			setValue('foundation_q2', foundation);
			setHasBasement(true);
		} else {
			setValue('foundation_q', foundation);
		}
	}, [foundation, setValue]);

	return (
		<div className='bf-foundation-q'>
			<div className='bf-header'>
				<Logo />
				<p className='bf-title-address'>
					<i className='fas fa-map-marked'></i>
					{address}, {city}, {state} {zip}
				</p>
			</div>
			<form>
				<h2 className='bf-title'>Which home best describes yours?</h2>
				<div className='row'>
					<div className='col l2 m3 s12 center-align'>
						<input onClick={onSubmit} type='radio' id='control_1' name='foundation_q' value='slab' ref={register} />
						<label htmlFor='control_1'>
							<div className='bf-label-wrapper'>
								<div className='bf-icon-wrapper'>
									<img className='bf-icon bf-icon-standard' src={slab} alt='Home with slab icon' />
									<img className='bf-icon bf-icon-hover' src={slabHover} alt='Home with slab icon' />
								</div>
								<div className='bf-label-text-wrapper'>
									<h4>Home on Slab</h4>
								</div>
							</div>
						</label>
					</div>
					<div className='col l2 m3 s12 center-align'>
						<input onClick={onSubmit} type='radio' id='control_2' name='foundation_q' value='basement' ref={register} />
						<label htmlFor='control_2'>
							<div className='bf-label-wrapper'>
								<div className='bf-icon-wrapper'>
									<img className='bf-icon bf-icon-standard' src={basement} alt='Home with basement icon' />
									<img className='bf-icon bf-icon-hover' src={basementHover} alt='Home with basement icon' />
								</div>
								<div className='bf-label-text-wrapper'>
									<h4>Home with Basement</h4>
								</div>
							</div>
						</label>
					</div>
					<div className='col l2 m3 s12 center-align'>
						<input onClick={onSubmit} type='radio' id='control_3' name='foundation_q' value='crawl_open' ref={register} />
						<label htmlFor='control_3'>
							<div className='bf-label-wrapper'>
								<div className='bf-icon-wrapper'>
									<img className='bf-icon bf-icon-standard' src={crawlspace} alt='Home with crawlspace icon' />
									<img className='bf-icon bf-icon-hover' src={crawlspaceHover} alt='Home with crawlspace icon' />
								</div>
								<div className='bf-label-text-wrapper'>
									<h4>Home on Crawl Space</h4>
								</div>
							</div>
						</label>
					</div>
					<div className='col l2 m3 s12 center-align'>
						<input onClick={onSubmit} type='radio' id='control_4' name='foundation_q' value='elev_free' ref={register} />
						<label htmlFor='control_4'>
							<div className='bf-label-wrapper'>
								<div className='bf-icon-wrapper'>
									<img className='bf-icon bf-icon-standard' src={piers} alt='Home with piers icon' />
									<img className='bf-icon bf-icon-hover' src={piersHover} alt='Home with piers icon' />
								</div>
								<div className='bf-label-text-wrapper'>
									<h4>Home Elevated on Peirs / Posts / Piles</h4>
								</div>
							</div>
						</label>
					</div>
					<div className='col l2 m3 s12 center-align'>
						<input onClick={onSubmit} type='radio' id='control_5' name='foundation_q' value='garage_open' ref={register} />
						<label htmlFor='control_5'>
							<div className='bf-label-wrapper'>
								<div className='bf-icon-wrapper'>
									<img className='bf-icon bf-icon-standard' src={garage} alt='Home with garage icon' />
									<img className='bf-icon bf-icon-hover' src={garageHover} alt='Home with garage icon' />
								</div>
								<div className='bf-label-text-wrapper'>
									<h4>Home on top of Garage</h4>
								</div>
							</div>
						</label>
					</div>
				</div>
				{hasBasement && (
					<div className='row bf-section-two'>
						<div className='col s10 offset-s1 center-align'>
							<h3 className='bf-title'>Which Basement best describes yours?</h3>
						</div>
						<div className='col l2 offset-l2 m3 s12 center-align'>
							<input onClick={onSubmit} type='radio' id='control_6' name='foundation_q2' value='base_below' ref={register} />
							<label className='bf-sublabel' htmlFor='control_6'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={basement} alt='Home with basement icon' />
										<img className='bf-icon bf-icon-hover' src={basementHover} alt='Home with basement icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Basement Fully Below Grade</h4>
									</div>
								</div>
							</label>
						</div>
						<div className='col l2 m3 s12 center-align'>
							<input onClick={onSubmit} type='radio' id='control_7' name='foundation_q2' value='base_partbelow' ref={register} />
							<label className='bf-sublabel' htmlFor='control_7'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={basement2} alt='Home with basement icon' />
										<img className='bf-icon bf-icon-hover' src={basement2Hover} alt='Home with basement icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Basement Partially Below Grade</h4>
									</div>
								</div>
							</label>
						</div>
						<div className='col l2 m3 s12 center-align'>
							<input onClick={onSubmit} type='radio' id='control_8' name='foundation_q2' value='base_walkout' ref={register} />
							<label className='bf-sublabel' htmlFor='control_8'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={basement3} alt='Home with basement icon' />
										<img className='bf-icon bf-icon-hover' src={basement3Hover} alt='Home with basement icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Walkout Basement</h4>
									</div>
								</div>
							</label>
						</div>
					</div>
				)}
			</form>
			<div className='bf-button-wrapper'>
				<button onClick={() => history.push('./building')} className='bf-back'>
					<i className='fas fa-angle-left'></i> BACK
				</button>
				{foundation && (
					<button onClick={() => history.push('./constr-frame')} className='bf-next'>
						NEXT <i className='fas fa-angle-right'></i>
					</button>
				)}
			</div>
		</div>
	);
};

export default FoundationQ;
