import { useCookies } from 'react-cookie';
import BeyondLogo from '../../assets/BF_Logo_with_Text.png';
import AllStateLogo from '../../assets/allstate1.png';

export default function Logo() {
  const [cookies] = useCookies(['leadref_id']);

  return (cookies.leadref_id ==='ltvgryvheol'|| cookies.leadref_id ==='6zvvnm3yx7b' ||cookies.leadref_id ==='je4tfgs9sxg')? (
    <div className='bf-logo-wrapper'>
      <div className='row'>
        <div className='col s6'>
          <a href="https://www.beyondfloods.com/" target="_blank" rel="noreferrer">
            <img src={BeyondLogo} alt='Beyond Floods Logo' className='bf-logo' />
          </a>
        </div>
        <div className='col s6'>
          <a href="https://www.allstate.com/home-insurance/flood-insurance" target="_blank" rel="noreferrer">
            <img src={AllStateLogo} alt='AllState Logo' className='bf-logo' />
          </a>
        </div>
      </div>
    </div>
  ) : (
      <a href="https://www.beyondfloods.com/" target="_blank" rel="noreferrer">
        <img src={BeyondLogo} alt='Beyond Floods Logo' className='bf-logo' />
      </a>
  );
}
