import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { chooseBuilding, setRouteLeadInfo } from '../../reducers/inputs';
import luckyOrange from '../../actions/luckyOrangeTag';
import { fetchRouteLead } from '../../actions/routeLead';
import { gaEvent } from '../../actions/ganalytics';
import Logo from '../common/Logo';

/**
 * Image imports
 */
import singleFam from '../../assets/single_fam.png';
import singleFamHover from '../../assets/single_fam_hover.png';
import townHome from '../../assets/townhome.png';
import townHomeHover from '../../assets/townhome_hover.png';
import condo from '../../assets/condo.png';
import condoHover from '../../assets/condo_hover.png';
import multiFam from '../../assets/multi_family.png';
import multiFamHover from '../../assets/multi_family_hover.png';
import mobile from '../../assets/mobile.png';
import mobileHover from '../../assets/mobile_hover.png';
import commercial from '../../assets/commercial.png';
import commercialHover from '../../assets/commercial_hover.png';
import other from '../../assets/other.png';
import otherHover from '../../assets/other_hover.png';

import axios from 'axios';

import { useCookies } from 'react-cookie';
import Unavailable from '../unavailable/Unavailable';

const BuildingQ = () => {
	// eslint-disable-next-line
	const [cookies, setCookie] = useCookies(['leadref_id']);
	const [availability, setAvailability] = useState(true);
	const [availabilityReason, setAvailabilityReason] = useState(true);
	const [routeLead, setRouteLead] = useState();
	const dispatch = useDispatch();
	const history = useHistory();
	const currentState = useSelector((state) => state);
	const building = useSelector((state) => state.building_q);
	const address = useSelector((state) => state.address);
	const city = useSelector((state) => state.city);
	const state = useSelector((state) => state.state);
	const zip = useSelector((state) => state.zip);
	const { register, handleSubmit } = useForm({ defaultValues: { building_q: building } });

	if (cookies.leadref_id) {
		luckyOrange(cookies.leadref_id);
	}

	if (!address) {
		history.push('./');
	}

	const fetchToken = async () => {
		await axios.get('/api/token');
	};

	const onSubmit = async (data) => {
		dispatch(chooseBuilding(data.building_q));
		if (data.building_q === 'single_family' || data.building_q === 'single_family_attached') {
			history.push('./foundation');
		} else {
			// Fetch route lead data for a bad state
			const routeLeadFetch = await fetchRouteLead({ leadref_id: currentState.leadref_id, address: address, city: city, state: state, zip: zip });
			const routeLeadData = routeLeadFetch.data;
			gaEvent({
				action: 'kickout_building',
				category: 'kickout_event',
				label: 'Kickout at Building Type',
			});
			dispatch(setRouteLeadInfo(routeLeadData));
			setRouteLead(routeLeadData.routelead_info);
			setAvailabilityReason('building');
			setAvailability(false);
		}
	};

	fetchToken();

	return (
		<div className='bf-building-q'>
			<div className='bf-header'>
				<Logo />
				<p className='bf-title-address'>
					<i className='fas fa-map-marked'></i>
					{address}, {city}, {state} {zip}
				</p>
			</div>
			{availability && (
				<form className='bf-building-selection'>
					<h2 className='bf-title'>Which best describes your property?</h2>
					<div className='row'>
						<div className='col l2 offset-l2 m3 s12 center-align'>
							<input onClick={handleSubmit(onSubmit)} type='radio' id='control_1' name='building_q' value='single_family' ref={register} />
							<label htmlFor='control_1'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={singleFam} alt='Single Family Home Icon' />
										<img className='bf-icon bf-icon-hover' src={singleFamHover} alt='Single Family Home Icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Single Family Home</h4>
									</div>
								</div>
							</label>
						</div>
						<div className='col l2 m3 s12 center-align'>
							<input onClick={handleSubmit(onSubmit)} type='radio' id='control_2' name='building_q' value='single_family_attached' ref={register} />
							<label htmlFor='control_2'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={townHome} alt='Townhome Icon' />
										<img className='bf-icon bf-icon-hover' src={townHomeHover} alt='Townhome Icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Attached Single Family Home<span>(Townhome, Duplex, etc.)</span></h4>
									</div>
								</div>
							</label>
						</div>
						<div className='col l2 m3 s12 center-align'>
							<input onClick={handleSubmit(onSubmit)} type='radio' id='control_3' name='building_q' value='condo' ref={register} />
							<label htmlFor='control_3'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={condo} alt='Condominium Icon' />
										<img className='bf-icon bf-icon-hover' src={condoHover} alt='Condominium Icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Condominium</h4>
									</div>
								</div>
							</label>
						</div>
						<div className='col l2 m3 s12 center-align'>
							<input onClick={handleSubmit(onSubmit)} type='radio' id='control_4' name='building_q' value='multi_family' ref={register} />
							<label htmlFor='control_4'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={multiFam} alt='Multi-Family Home Icon' />
										<img className='bf-icon bf-icon-hover' src={multiFamHover} alt='Multi-Family Home Icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Multi-family Home</h4>
									</div>
								</div>
							</label>
						</div>
						<div className='col l2 offset-l4 m3 s12 center-align'>
							<input onClick={handleSubmit(onSubmit)} type='radio' id='control_5' name='building_q' value='manufactured' ref={register} />
							<label htmlFor='control_5'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={mobile} alt='Mobile/Manufactured Home Icon' />
										<img className='bf-icon bf-icon-hover' src={mobileHover} alt='Mobile/Manufactured Home Icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Mobile or Manufactured Home</h4>
									</div>
								</div>
							</label>
						</div>
						<div className='col l2 m3 s12 center-align'>
							<input onClick={handleSubmit(onSubmit)} type='radio' id='control_6' name='building_q' value='commercial' ref={register} />
							<label htmlFor='control_6'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={commercial} alt='Commercial Building Icon' />
										<img className='bf-icon bf-icon-hover' src={commercialHover} alt='Commercial Building Icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Commercial Building</h4>
									</div>
								</div>
							</label>
						</div>
						<div className='col l2 m3 s12 center-align'>
							<input onClick={handleSubmit(onSubmit)} type='radio' id='control_7' name='building_q' value='other' ref={register} />
							<label htmlFor='control_7'>
								<div className='bf-label-wrapper'>
									<div className='bf-icon-wrapper'>
										<img className='bf-icon bf-icon-standard' src={other} alt='Other Building Icon' />
										<img className='bf-icon bf-icon-hover' src={otherHover} alt='Other Building Icon' />
									</div>
									<div className='bf-label-text-wrapper'>
										<h4>Other</h4>
									</div>
								</div>
							</label>
						</div>
					</div>
				</form>
			)}
			{!availability && <Unavailable reason={availabilityReason} contact={routeLead} form={true} />}
			<div className='bf-button-wrapper'>
				{availability && (
					<button onClick={() => history.push('./')} className='bf-back'>
						<i className='fas fa-angle-left'></i> BACK
					</button>
				)}
				{building && availability && (
					<button onClick={() => history.push('./foundation')} className='bf-next'>
						NEXT <i className='fas fa-angle-right'></i>
					</button>
				)}
			</div>
		</div>
	);
};

export default BuildingQ;
