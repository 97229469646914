import { createSlice } from '@reduxjs/toolkit';
import { formatPhoneNumber } from '../actions/helpers';

const rootSlice = createSlice({
	name: 'root',
	initialState: {
		client_id: '',
		leadref_id: '',
		preloadinfo_id: '',
		geoc_lat: '',
		geoc_lng: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		building_q: '',
		foundation_q: '',
		num_floors: 0,
		constr_frame: '',
		year_built: '',
		occupancy_q: '',
		rep_cost: 0,
		user_cov_a_bldg: 0,
		user_cov_c_cont: 0,
		user_deductible: 5000,
		leadrecord_stage: '',
		applicant: '',
		appl_email: '',
		appl_phone: '',
		routelead_info: {
			agent_bfid: '',
			agent_contactname: '',
			agent_contactemail: '',
			agent_contactphone: '',
			agent_location: '',
			agent_agencyname: '',
			show_agent_info: true,
			lead_email_type: '',
			lead_email_inbox: '',
			lead_source: '',
			lead_crmload: '',
		},
		quickquote_id: '',
		quote_set: [],
		quotes: {
			essential: {},
			enhanced: {},
			elite: {},
		},
		bf_score: '',
		bf_score_cat: '',
		bfquickquote_status_list: [],
		selected_tier: '',
		zone_eff: '',
		bfe_eff: '',
		appl_reason: [],
		appl_reason_count: 0,
		timestamp: '',
		mortgage_related: '',
		current_flood_ins: '',
		eff_date_req: '',
		platform_quote_id: '',
	},
	reducers: {
		setPlatformId: (state, action) => {
			state.platform_quote_id = action.payload;
		},
		setClientId: (state, action) => {
			state.client_id = action.payload;
		},
		setApplReason: (state, action) => {
			state.appl_reason = action.payload;
		},
		setApplReasonCount: (state, action) => {
			state.appl_reason_count = action.payload;
		},
		setTimestamp: (state, action) => {
			state.timestamp = action.payload;
		},
		setQuickQuoteID: (state, action) => {
			state.quickquote_id = action.payload;
		},
		setQuoteSet: (state, action) => {
			state.quote_set = action.payload;
		},
		setQuotes: (state, action) => {
			state.quotes = {
				essential: action.payload[0],
				enhanced: action.payload[1],
				elite: action.payload[2],
			};
		},
		setStatusList: (state, action) => {
			state.bfquickquote_status_list = action.payload;
		},
		setQuoteSelected: (state, action) => {
			state.selected_tier = action.payload;
		},
		setFloodZone: (state, action) => {
			state.zone_eff = action.payload;
		},
		setFloodElevation: (state, action) => {
			state.bfe_eff = action.payload;
		},
		setLattitude: (state, action) => {
			state.geoc_lat = action.payload;
		},
		setLongitude: (state, action) => {
			state.geoc_lng = action.payload;
		},
		chooseAddress: (state, action) => {
			state.address = action.payload;
		},
		chooseCity: (state, action) => {
			state.city = action.payload;
		},
		chooseState: (state, action) => {
			state.state = action.payload;
		},
		chooseZip: (state, action) => {
			state.zip = action.payload;
		},
		chooseBuilding: (state, action) => {
			state.building_q = action.payload;
		},
		chooseFoundation: (state, action) => {
			state.foundation_q = action.payload;
		},
		chooseFrame: (state, action) => {
			state.constr_frame = action.payload;
		},
		setYear: (state, action) => {
			state.year_built = action.payload;
		},
		setStories: (state, action) => {
			state.num_floors = action.payload;
		},
		chooseOccupancy: (state, action) => {
			state.occupancy_q = action.payload;
		},
		setReplacementCost: (state, action) => {
			state.rep_cost = action.payload;
		},
		setCoverageA: (state, action) => {
			state.user_cov_a_bldg = action.payload;
		},
		setCoverageC: (state, action) => {
			state.user_cov_c_cont = action.payload;
		},
		setDeductible: (state, action) => {
			state.user_deductible = action.payload;
		},
		setLeadRecordStage: (state, action) => {
			state.leadrecord_stage = action.payload;
		},
		setApplicant: (state, action) => {
			state.applicant = action.payload;
		},
		setApplicantEmail: (state, action) => {
			state.appl_email = action.payload;
		},
		setApplicantPhone: (state, action) => {
			state.appl_phone = formatPhoneNumber(action.payload);
		},
		setLeadRefId: (state, action) => {
			state.leadref_id = action.payload;
		},
		setPreloadId: (state, action) => {
			state.preloadinfo_id = action.payload;
		},
		setRouteLeadInfo: (state, action) => {
			state.routelead_info = {
				agent_agencyname: action.payload.routelead_info.agent_agencyname,
				agent_contactname: action.payload.routelead_info.agent_contactname,
				agent_bfid: action.payload.routelead_info.agent_bfid,
				agent_contactemail: action.payload.routelead_info.agent_contactemail,
				agent_contactphone: formatPhoneNumber(action.payload.routelead_info.agent_contactphone),
				agent_location: action.payload.routelead_info.agent_location,
				show_agent_info: action.payload.routelead_info.show_agent_info,
				lead_email_type: action.payload.lead_email_type,
				lead_email_inbox: action.payload.lead_email_inbox,
				lead_source: action.payload.lead_source,
				lead_crmload: action.payload.lead_crmload,
			};
		},
		setMortgageRelated: (state, action) => {
			state.mortgage_related = action.payload;
		},
		setCurrentIns: (state, action) => {
			state.current_flood_ins = action.payload;
		},
		setEffectiveDate: (state, action) => {
			state.eff_date_req = action.payload;
		},
		setBfScore: (state, action) => {
			state.bf_score = action.payload;
		},
		setBfScoreCat: (state, action) => {
			state.bf_score_cat = action.payload;
		},
	},
});

export const inputReducer = rootSlice.reducer;

export const {
	setPlatformId,
	setBfScore,
	setBfScoreCat,
	setQuickQuoteID,
	setApplReason,
	setApplReasonCount,
	setTimestamp,
	setQuoteSet,
	setQuotes,
	setStatusList,
	setQuoteSelected,
	setFloodZone,
	setFloodElevation,
	setLattitude,
	setLongitude,
	chooseAddress,
	chooseCity,
	chooseState,
	chooseZip,
	chooseBuilding,
	chooseFoundation,
	chooseFrame,
	setYear,
	setStories,
	chooseOccupancy,
	setReplacementCost,
	setCoverageA,
	setCoverageC,
	setDeductible,
	setLeadRecordStage,
	setApplicant,
	setApplicantEmail,
	setApplicantPhone,
	setLeadRefId,
	setPreloadId,
	setRouteLeadInfo,
	setClientId,
	setMortgageRelated,
	setCurrentIns,
	setEffectiveDate,
} = rootSlice.actions;
