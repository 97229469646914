/**
 * This function takes in a leadRefId and tags the Lucky Orange session with that leadref ID so that you can see the different campaigns in the lucky orange dashboard
 * @param {string} leadRefId - the leadref ID associated with a campaign 
 */
const luckyOrange = (leadRefId) => {
	window._loq = window._loq || [];
	window._loq.push(['tag', leadRefId]);
};

export default luckyOrange;
