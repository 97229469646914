import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { chooseAddress, chooseCity, chooseState, chooseZip, setLeadRefId, setLattitude, setLongitude, setRouteLeadInfo, setClientId } from '../../reducers/inputs';

import Logo from '../common/Logo';
import Unavailable from '../unavailable/Unavailable';
import luckyOrange from '../../actions/luckyOrangeTag';
import { gaEvent } from '../../actions/ganalytics';
import { extractFromAdress, isNatGenLeadref } from '../../actions/helpers';
import { natgenFloodlightQuoteStart } from '../../actions/natgenGoogleFloodlight/natgenFloodlightQuoteStart';
import { natgenFloodlightQuoteStartButton } from '../../actions/natgenGoogleFloodlight/natgenFloodlightQuoteStartButton';
import { fetchRouteLead } from '../../actions/routeLead';
import bfMap from '../../assets/bf_map.png';
import NatGen_hor from '../../assets/NatGen_hor.png';

export default function AddressQ() {
	const dispatch = useDispatch();
	const history = useHistory();

	// Checks url for leadref in query string
	const { search } = useLocation();
	const values = queryString.parse(search);

	// React useState variables
	const [address, setAddress] = useState('');
	const [manualAddress, setManualAddress] = useState('');
	const [manualCity, setManualCity] = useState('');
	const [manualState, setManualState] = useState('');
	const [manualZip, setManualZip] = useState('');
	const [errors, setErrors] = useState(false);
	const [manual, setManual] = useState(false);
	const [cookies, setCookie] = useCookies(['leadref_id']);
	const [availability, setAvailability] = useState(true);
	const [availabilityReason, setAvailabilityReason] = useState('');
	const [routeLead, setRouteLead] = useState();

	// Pull all the variables currently stored in the reducer into a useable variable
	const currentState = useSelector((state) => state);

	// react-hook-form components
	const { register, handleSubmit } = useForm();

	useEffect(() => {
		/**
		 * Checks for leadref in URL first. If there, set cookie to new leadref, set leadref in reducer, and  send value to Lucky Orange
		 * If no leadref in URL, check for cookie. If cookie already exists, set leadref in reducer and send value to Lucky Orange.
		 */
		if (values.leadref) {
			setCookie('leadref_id', values.leadref, { path: '/' });
			dispatch(setLeadRefId(values.leadref));
			luckyOrange(values.leadref);
			history.push('./');
		} else if (cookies.leadref_id) {
			dispatch(setLeadRefId(cookies.leadref_id));
			luckyOrange(cookies.leadref_id);
		} else {
			// console.log('No LeadRef');
		}

		// Check if manual location entry is in the url params
		if (values.manualentry === '1') {
			setManual(true)

			// Check to see if we can prefill any address fields
			setManualAddress(values.address)
			setManualCity(values.city)
			setManualState(values.state)
			setManualZip(values.zip)
		}

		ReactGA.ga((tracker) => {
			dispatch(setClientId(tracker.get('clientId')));
		});

    	// Only trigger in live for NatGen leadrefs
		if (isNatGenLeadref(currentState.leadref_id) && process.env.REACT_APP_ENV === "live") {
			natgenFloodlightQuoteStart();
		}
	}, [values, cookies.leadref_id, currentState.leadref_id, history, setCookie, dispatch]);

	/**
	 * Options for PlacesAutoComplete
	 * https://www.npmjs.com/package/react-places-autocomplete
	 */
	const searchOptions = {
		componentRestrictions: { country: "us" },
		types: ['address'],
	};

	/**
	 * List of states that we offer BeyondFloods coverage in
	 */
	const availableStates = [
		'AL',
		'AZ',
		'CA',
		'CO',
		'CT',
		'FL',
		'GA',
		'IA',
		'ID',
		'IL',
		'IN',
		'LA',
		'MA',
		'MD',
		'ME',
		'MI',
		'MN',
		'MS',
		'NC',
		'NH',
		'NJ',
		'NM',
		'OH',
		'OR',
		'PA',
		'RI',
		'SC',
		'TN',
		'TX',
		'VA',
		'VT',
		'WA',
		'WV',
	];

	/**
	 * Function handles switching back and forth between the manual and autocomplete address options
	 */
	const handleManualClick = () => {
		setErrors(false);
		if (manual) {
			setManual(false);
		} else {
			setManual(true);
		}
	};

	/**
	 * Handler function for typing a full address in the autocomplete window but instead of selecting an address from the dropdown, clicking "Start Quote"
	 * @param {string} value - String typed into autocomplete box
	 */
	const handleAddressSubmit = async (value) => {
		// Fetch route lead data for a bad state
		const routeLeadFetch = await fetchRouteLead({ leadref_id: currentState.leadref_id, address: '', city: '', state: '', zip: '' });

		const routeLeadData = routeLeadFetch.data;

		dispatch(setRouteLeadInfo(routeLeadData));
		setRouteLead(routeLeadData.routelead_info);
		setAvailabilityReason('address');
		gaEvent({
			action: 'kickout_address',
			category: 'kickout_event',
			label: 'Kickout for Bad Address',
		});
		setAvailability(false);
	};

	//
	/**
	 * Handler function for manually typing in an address on the manual form and clicking submit
	 * @param {value} Object of address data from manual form
	 */
	const handleFormSubmit = async (value) => {
		// Check to make sure it is in a state we offer coverage
		dispatch(chooseAddress(value.address));
		dispatch(chooseCity(value.city));
		dispatch(chooseState(value.state));
		dispatch(chooseZip(value.zip));

		// Only trigger in live for NatGen leadrefs
		if (isNatGenLeadref(currentState.leadref_id) && process.env.REACT_APP_ENV === "live") {
			natgenFloodlightQuoteStartButton();
		}

		if (availableStates.includes(value.state)) {
			history.push('./building');
		} else {
			stateUnavailable({
				address: value.address,
				city: value.city,
				state: value.state,
				zip: value.zip,
			});
		}
	};

	// Function handles what happens when you select an address from the autocomplete dropdown
	/**
	 * Handler function for selecting an address from the autocomplete dropdown
	 * @param {string} value - String version of address from Google AutoComplete
	 */
	const handleSelect = async (value) => {
		setErrors(false);
		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);
		dispatch(setLattitude(latLng.lat));
		dispatch(setLongitude(latLng.lng));

		// Extract the desired address components from the Google return
		const address = extractFromAdress(results[0].address_components, 'street_number') + ' ' + extractFromAdress(results[0].address_components, 'route');
		const city = extractFromAdress(results[0].address_components, 'locality') || extractFromAdress(results[0].address_components, 'sublocality');
		const state = extractFromAdress(results[0].address_components, 'administrative_area_level_1');
		const zip = extractFromAdress(results[0].address_components, 'postal_code');

		// Build standard looking address from components
		setAddress(address + ', ' + city + ', ' + state + ' ' + zip);

		// Check to see if the geolocation is a Rooftop or range interpolated to eliminate estimated addresses
		if (results[0].geometry.location_type === 'ROOFTOP' || results[0].geometry.location_type === 'RANGE_INTERPOLATED') {
			setErrors(false);
			dispatch(chooseAddress(address));
			dispatch(chooseCity(city));
			dispatch(chooseState(state));
			dispatch(chooseZip(zip));
			// Check to make sure it is in a state we offer coverage
			if (availableStates.includes(state)) {
				history.push('./building');
			} else {
				stateUnavailable({
					leadref_id: currentState.leadref_id,
					address: address,
					city: city,
					state: state,
					zip: zip,
					geoc_lat: latLng.lat,
					geoc_lon: latLng.lng,
				});
			}
		} else {
			setErrors(true);
		}
	};

	const stateUnavailable = async (params) => {
		// Fetch route lead data for a bad state
		const routeLeadFetch = await fetchRouteLead(params);

		const routeLeadData = routeLeadFetch.data;
		gaEvent({
			action: 'kickout_state',
			category: 'kickout_event',
			label: 'Kickout for State Coverage',
		});
		dispatch(setRouteLeadInfo(routeLeadData));
		setRouteLead(routeLeadData.routelead_info);
		setAvailabilityReason('state');
		setAvailability(false);
	};

	return (
		<div className='bf-address-q'>
			<Logo />
			{availability && (
				<div className='bf-search-wrapper'>
					<p className='bf-title'>Start a quote by typing your address below:</p>
					{!manual && (
						<div className='bf-autocomplete-wrapper'>
							<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect} searchOptions={searchOptions} highlightFirstSuggestion={true}>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
									<div className='bf-autocomplete-form-wrapper'>
										<form className='bf-address-form' onSubmit={handleSubmit(handleAddressSubmit)}>
											<div className='search-location-input'>
												<input {...getInputProps({ placeholder: 'Type address' })} name='autocomplete_address' ref={register} />
												{suggestions.length > 0 && (
													<div className='bf-dropdown'>
														{suggestions.map((suggestion, key) => {
															const style = {
																backgroundColor: suggestion.active ? '#007fbd' : '#fff',
																color: suggestion.active ? '#fff' : '#000',
															};

															return (
																<div className='pac-item' key={key} {...getSuggestionItemProps(suggestion, { style })}>
																	{suggestion.description}
																</div>
															);
														})}
													</div>
												)}
											</div>
											<button className='bf-button'>Start Quote</button>
										</form>
										{errors && <div className='bf-address-error'>Please enter a valid and complete address.</div>}
									</div>
								)}
							</PlacesAutocomplete>

							<button className='bf-address-toggle' onClick={handleManualClick}>
								<i className='fas fa-map-marker-alt'></i> Enter Address Manually
							</button>
						</div>
					)}
					{manual && (
						<div className='bf-manual-wrapper'>
							<form onSubmit={handleSubmit(handleFormSubmit)} className='bf-manual-address-form'>
								<div className='bf-address-wrapper'>
									<label htmlFor='address'>Address</label>
									<input id='bf-manual-address' name='address' ref={register} defaultValue={manualAddress} />
								</div>
								<div className='bf-city-wrapper'>
									<label htmlFor='city'>City</label>
									<input id='bf-manual-city' name='city' ref={register} defaultValue={manualCity} ></input>
								</div>
								<div className='bf-state-wrapper'>
									<label htmlFor='state'>State</label>
									<select id='bf-manual-state' name='state' ref={register({ required: true })} defaultValue={manualState}>
										<option className='default' value=''></option>
										{availableStates.map((state) => (
											<option key={state} value={state}>{state}</option>
										))}
									</select>
								</div>
								<div className='bf-zip-wrapper'>
									<label htmlFor='zip'>Zip Code</label>
									<input id='bf-manual-zip' name='zip' ref={register} defaultValue={manualZip} />
								</div>
								{errors && <div className='bf-address-error'>Please enter a valid and complete address.</div>}
								<div className='bf-button-wrapper'>
									<button>Submit</button>
								</div>
							</form>
							<button className='bf-address-toggle' onClick={handleManualClick}>
								<i className='fas fa-undo'></i> Reset Location
							</button>
						</div>
					)}
					<div className='bf-home-container'>
						<div className='row'>
							<div className='col l6 push-l6 s12 left-align center-align-on-med-and-down'>
								<div className='bf-state-list'>
									<h4>Beyond Floods is a product of:</h4>
									<img src={NatGen_hor} alt='National General an Allstate Company' className='bf-natgen-logo' />
									<p>
										Beyond Floods is now available to any National General agent in these {availableStates.length} US states- {availableStates.join(", ")}.
									</p>
								</div>
							</div>
							<div className='col l6 pull-l6 s12'>
								<img src={bfMap} alt='Beyond Floods Coverage Map' className='bf-map' />
							</div>
						</div>
					</div>
				</div>
			)}
			{!availability && <Unavailable reason={availabilityReason} contact={routeLead} form={true} />}

			{/* // NatGen Floodlight */}
			<noscript>
				{ /* eslint-disable no-template-curly-in-string */ }
				<img src="https://ad.doubleclick.net/ddm/activity/src=1886972;type=natg694;cat=ngbey00;u1=1;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?" width="1" height="1" alt=""/>
				<img src="https://ad.doubleclick.net/ddm/activity/src=1886972;type=natg694;cat=ngbey0;u1=[orderID];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?" width="1" height="1" alt=""/>
				{ /* eslint-enable no-template-curly-in-string */ }
			</noscript>
		</div>
	);
}
