import React from "react";
const FloodPlaceholder = ({ msgList }) =>{
  // console.log(msgList)
  // const [message, setMessage] = useState(msgList[0])
  // const [ind, setInd] = useState(0)
  // if(ind>6){
  //   setInd(0);
  // }
  // useEffect(() => {
  //   setTimeout(() => {
  //     setInd(ind+1);
  //     setMessage(msgList[ind]);
      
  //   }, 10000);
  // }, [ind])
    return(<div className="loading-spinner-placeholder">
        {
          msgList.map((msg,index) => <span style={{marginLeft:"50px"}} key ={index}> {msg}</span>)
        }
    </div>);
}
export default FloodPlaceholder;