import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import {
	chooseAddress,
	chooseCity,
	chooseState,
	chooseZip,
	chooseBuilding,
	chooseFoundation,
	setStories,
	chooseFrame,
	setYear,
	chooseOccupancy,
	setReplacementCost,
	setCoverageA,
	setCoverageC,
	setApplicant,
	setApplicantEmail,
	setApplicantPhone,
	setLeadRefId,
	setPreloadId,
} from '../../reducers/inputs';

import queryString from 'query-string';
import axios from 'axios';

import { useCookies } from 'react-cookie';

const QuoteRef = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const values = queryString.parse(search);
	// eslint-disable-next-line
	const [cookies, setCookie] = useCookies(['leadref_id']);

	/*
	Expected values
	/qqpl?leadref=&inforef=y
	?leadref = leadref ID for campaign 
	?inforef = preload ID for specific client info
	*/
	// https://quickquote.beyondfloods.com/qqpl?inforef=6cvq4vgsfj1&leadref=whjft6o6fv7
	// console.log(values); // "top"

	const fetchQuickQuoteInfo = async () => {
		try {
			let response = await axios.post('/api/quickquote-preload', { params: { preloadinfo_id: values.inforef, leadref_id: values.leadref } });
			let quoteInfo = response.data.bfpreloadinfo_data;

			dispatch(chooseAddress(quoteInfo.address));
			dispatch(chooseCity(quoteInfo.city));
			dispatch(chooseState(quoteInfo.state));
			dispatch(chooseZip(quoteInfo.zip));
			dispatch(chooseBuilding('single_family'));
			dispatch(chooseFoundation(quoteInfo.foundation_q));
			dispatch(setStories(quoteInfo.num_floors));
			dispatch(chooseFrame(quoteInfo.constr_frame));
			dispatch(setYear(quoteInfo.year_built));
			dispatch(chooseOccupancy(quoteInfo.occupancy_q));
			dispatch(setReplacementCost(quoteInfo.rep_cost));
			dispatch(setCoverageA(quoteInfo.user_cov_a_bldg));
			dispatch(setCoverageC(quoteInfo.user_cov_c_cont));
			dispatch(setApplicant(quoteInfo.applicant));
			dispatch(setApplicantEmail(quoteInfo.appl_email));
			dispatch(setApplicantPhone(quoteInfo.appl_phone));
			dispatch(setLeadRefId(values.leadref));
			dispatch(setPreloadId(values.inforef));

			setTimeout(() => {
				setCookie('leadref_id', values.leadref, { path: '/' });
				history.push('/foundation');
			}, 400);
		} catch (error) {
			console.error(error.message);
		}

	};

	useEffect(() => {
		fetchQuickQuoteInfo();
	});

	return <div className='bf-quoteref'></div>;
};

export default QuoteRef;
