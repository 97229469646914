import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { logLeadRecord } from '../../actions/leadRecord';
// import { sendKickoutEmail } from '../../actions/email';
import { gaEvent } from '../../actions/ganalytics';
import { setApplicant, setApplicantEmail, setApplicantPhone } from '../../reducers/inputs';
import MarketingComDisclaimer from '../common/MarketingComDisclaimer';

/**
 * 
 * @param {*} props - an object of props passed down from the unavaialble function. Contains the reason for the kickout event that led to the unavialable page.
 * @returns The unavaialble form to be displayed on the unavailable page
 */
function UnavailableForm(props) {
	const history = useHistory();
	const { register, handleSubmit, control } = useForm();
	const dispatch = useDispatch();

	// Create a local variable to toggle whether or not to show the form
	const [formFilled, setFormFilled] = useState(false);

	// Create a variable for all the information stored in the reducer
	const currentState = useSelector((state) => state);

	/**
	 * onSubmit runs when the unavaialble form is submitted
	 * @param {object} data - form data entered by the client 
	 */
	const onSubmit = async (data) => {

		// Set form filled to true to hide the form on submit
		setFormFilled(true);

		// Build the body for the lead record logger
		const reqBody = {
			...currentState,
			applicant: data.client_name,
			appl_email: data.client_email,
			appl_phone: data.client_phone,
		};

		// Trigger lead record logger with kickout reason
		await logLeadRecord(reqBody, 'kickout_' + props.reason + '_form');

		// Store applicant data in the reducer
		dispatch(setApplicant(data.applicant));
		dispatch(setApplicantEmail(data.appl_email));
		dispatch(setApplicantPhone(data.appl_phone));

		// log kickout event in google analytics
		gaEvent({
			action: 'kickout_form_filled',
			category: 'engagement',
			label: 'Client Filled Out Kickout Form',
		});

		// send email here
		// Temporarily disabled
		// await sendKickoutEmail(reqBody);
		history.push('./thank-you');
	};

	return (
		<div className='bf-form-container'>
			{!formFilled && (
				<form onSubmit={handleSubmit(onSubmit)} className='bf-form bf-unavialable-form'>
					<div className='row'>
						<div className='col m6 s12 left-align center-align-on-small-only'>
							<div className='bf-input-wrapper'>
								<i className='fas fa-user-circle'></i>
								<Controller as={<input />} control={control} required type='text' id='client_name' name='client_name' placeholder='Enter Your Name' defaultValue={currentState.applicant} />
							</div>
							<div className='bf-input-wrapper'>
								<i className='far fa-envelope'></i>
								<Controller
									as={<input />}
									control={control}
									required
									type='email'
									id='client_email'
									name='client_email'
									placeholder='Enter Your Email'
									defaultValue={currentState.appl_email}
								/>
							</div>
							<div className='bf-input-wrapper'>
								<i className='fas fa-phone'></i>
								<div>
									<Controller
										required
										defaultValue={currentState.appl_phone}
										name='client_phone'
										id='client_phone'
										control={control}
										as={<NumberFormat format='(###) ###-####' mask='_' />}
										placeholder='Enter Your Phone Number'
									/>
								</div>
							</div>
						</div>
						<div className='col m6 s12 right-align center-align-on-small-only'>
							<fieldset>
								<legend>
									Why are you requesting this quote?<span>Select all that apply</span>
								</legend>
								<label htmlFor='I am a homeowner'>
									<input name='reason' type='checkbox' value='I am a homeowner' ref={register} />I am a homeowner
								</label>
								<label htmlFor='I am buying a new home'>
									<input name='reason' type='checkbox' value='I am buying a new home' ref={register} />I am buying a new home
								</label>
								<label htmlFor='I am a Real Estate agent getting a quote for my customer'>
									<input name='reason' type='checkbox' value='I am a Real Estate agent getting a quote for my customer' ref={register} />I am a Real Estate agent getting a quote for my
									customer
								</label>
								<label htmlFor='My lender requires flood insurance'>
									<input name='reason' type='checkbox' value='My lender requires flood insurance' ref={register} />
									My lender requires flood insurance
								</label>
								<label htmlFor='I am just browsing'>
									<input name='reason' type='checkbox' value='I am just browsing' ref={register} />I am just browsing
								</label>
							</fieldset>
						</div>
					</div>
					<MarketingComDisclaimer button_name="Submit"/>
					<div className='bf-button-wrapper'>
						<button>Submit</button>
					</div>
				</form>
			)}
			{formFilled && <h5>Thank you. A representative will be in touch shortly.</h5>}
		</div>
	);
}

export default UnavailableForm;
