import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { chooseFrame } from '../../reducers/inputs';
import Logo from '../common/Logo';
import wood from '../../assets/wood.png';
import woodHover from '../../assets/wood-hover.png';
import steel from '../../assets/steel.png';
import steelHover from '../../assets/steel-hover.png';
import concrete from '../../assets/concrete.png';
import concreteHover from '../../assets/concrete-hover.png';

const ConstructionFrame = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const constr_frame = useSelector((state) => state.constr_frame);
	const foundation = useSelector((state) => state.foundation_q);
	const building = useSelector((state) => state.building_q);
	const address = useSelector((state) => state.address);
	const city = useSelector((state) => state.city);
	const state = useSelector((state) => state.state);
	const zip = useSelector((state) => state.zip);

	const { register, handleSubmit } = useForm({ defaultValues: { constr_frame: constr_frame } });

	if (!address) {
		history.push('./');
	} else if (!building) {
		history.push('./building');
	} else if (!foundation) {
		history.push('./foundation');
	}

	const onSubmit = (data) => {
		dispatch(chooseFrame(data.constr_frame));
		history.push('./more-info');
	};

	return (
		<div className='bf-step-three'>
			<div className='bf-header'>
				<Logo/>
				<p className='bf-title-address'>
					<i className='fas fa-map-marked'></i>
					{address}, {city}, {state} {zip}
				</p>
			</div>
			<form>
				<h2 className='bf-title'>Which material best describes your home's construction?</h2>
				<div className='row'>
					<div className='col l2 offset-l2 m3 s12 center-align'>
						<input onClick={handleSubmit(onSubmit)} type='radio' id='control_1' name='constr_frame' value='Wood' ref={register} />
						<label htmlFor='control_1'>
							<div className='bf-label-wrapper'>
								<div className='bf-icon-wrapper'>
									<img className='bf-icon bf-icon-standard' src={wood} alt='wood icon' />
									<img className='bf-icon bf-icon-hover' src={woodHover} alt='wood icon' />
								</div>
								<div className='bf-label-text-wrapper'>
									<h4>Wood</h4>
								</div>
							</div>
						</label>
					</div>
					<div className='col l2 m3 s12 center-align'>
						<input onClick={handleSubmit(onSubmit)} type='radio' id='control_2' name='constr_frame' value='Steel' ref={register} />
						<label htmlFor='control_2'>
							<div className='bf-label-wrapper'>
								<div className='bf-icon-wrapper'>
									<img className='bf-icon bf-icon-standard' src={steel} alt='steel icon' />
									<img className='bf-icon bf-icon-hover' src={steelHover} alt='steel icon' />
								</div>
								<div className='bf-label-text-wrapper'>
									<h4>Steel</h4>
								</div>
							</div>
						</label>
					</div>
					<div className='col l2 m3 s12 center-align'>
						<input onClick={handleSubmit(onSubmit)} type='radio' id='control_3' name='constr_frame' value='Concrete / Masonry' ref={register} />
						<label htmlFor='control_3'>
							<div className='bf-label-wrapper'>
								<div className='bf-icon-wrapper'>
									<img className='bf-icon bf-icon-standard' src={concrete} alt='concrete icon' />
									<img className='bf-icon bf-icon-hover' src={concreteHover} alt='concrete icon' />
								</div>
								<div className='bf-label-text-wrapper'>
									<h4>Concrete / Masonry</h4>
								</div>
							</div>
						</label>
					</div>
				</div>
			</form>
			<div className='bf-button-wrapper'>
				<button onClick={() => history.push('./foundation')} className='bf-back'>
					<i className='fas fa-angle-left'></i> BACK
				</button>
				{constr_frame && (
					<button onClick={() => history.push('./more-info')} className='bf-next'>
						NEXT <i className='fas fa-angle-right'></i>
					</button>
				)}
			</div>
		</div>
	);
};

export default ConstructionFrame;
