import axios from 'axios';
import { formatRepCost } from './helpers';

/**
 * Sends the initial quote email to the client after they give us their information on the Outlook page and also sends a separate but similar email to the agent from the routelead path if the send_agent_email boolean value is true
 * @param {object} params Body with all the props from current state and any additional information for the quote emails
 */
export const sendQuoteEmail = async (params) => {
	const paramBody = {
		...params,
		rep_cost: formatRepCost(params.rep_cost),
	};

	/**
	 * Sends information to client and agent email routes.
	 */
	await axios.post('/api/email/client', { params: paramBody });

	await axios.post('/api/email/agent', { params: paramBody });
};

/**
 * Sends information to the bind email api route at the node server to fire the bind email to the agent through SendGrid if the send_agent_email boolean value is true
 * @param {object} params Body with all the props from current state and any additional pertinant information
 */
export const sendBindEmail = async (params) => {
	await axios.post('/api/email/bind-now', { params: params });
};

/**
 * Sends information to the kickout emai api route at the node server to fire the kickout emails to the agent and client if the client fills out the form on the kickout page.
 * @param {object} params Body with all the props from the curent state aand any additional information needed for the emails
 */
export const sendKickoutEmail = async (params) => {
	/**
	 * Sends information to client kickout email route.
	 */
	await axios.post('/api/email/kickout', { params: params });

	/**
	 * Checks for send_agent_email variable and if true, sends information to agent kickout email route.
	 */
	// if (params.routelead_info.send_agent_email) {
	// await axios.post('/api/email/agent-kickout', { parmas: params });
	// }
};
