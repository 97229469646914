import React, {useState} from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ScrollToTop from './components/common/scrollTop';
import AddressQ from './components/1_address/AddressQ';
import BuildingQ from './components/2_buildingq/BuildingQ';
import FoundationQ from './components/3_foundationq/FoundationQ';
import ConstructionFrame from './components/4_constr_frame/ConstructionFrame';
import MoreInfo from './components/5_more_info/MoreInfo';
import Outlook from './components/6_outlook/Outlook';
import Result from './components/7_result/Result';
import BindNow from './components/8_bind_now/BindNow';
import ThankYou from './components/9_thank_you/ThankYou';
import QuoteRef from './components/quoteref/QuoteRef';
import PreloadRef from './components/preloadref/PreloadRef';
import QuickQuoteLoad from './components/quick-quote-load/QuickQuoteLoad';
import QQWidget from './components/widget/Widget';
import Footer from './components/common/Footer';
import './App.css';

/**
 * This is the Google Analytics code. The tracking ID is stored in the .env file inside the client folder. It is not in the GitHub repo. It is on the server and Zack also has a copy.
 */

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

/**
 * The main App function that houses the router and routes
 */
function App() {
	const [needLogin, setNeedLogin] = useState(process.env.REACT_APP_ENV==='testqq');

	// Testqq env only
	const checkPw = () => {
		const answer = document.getElementById("password").value;
	
		if (answer === "bf2023") { 
			setNeedLogin(false);
		}
	};

	return (
		<div className='container'>
			<BrowserRouter>
				{!needLogin ? <Routes />
				:
				(
					// Login only for testqq
					<form onSubmit={checkPw}>
					<p>This site is a test environment for <a href='https://www.beyondfloods.com/'>BeyondFloods.com</a></p> 
					<p>Password:</p>
					<input id="password" name="password" />
					<button>Enter</button>
					</form>
				)
				}
			</BrowserRouter>
			<Footer />
		</div>
	);
}

/**
 * The routes function that contains all the different routes for the web app
 * There is also a ScrollToTop wrapper that just scrolls to the top of the page when the route changes
 */
function Routes() {

	return (
		<ScrollToTop>
			<Switch>
				<Route exact path='/' component={AddressQ} />
				<Route path='/building' component={BuildingQ} />
				<Route path='/foundation' component={FoundationQ} />
				<Route path='/constr-frame' component={ConstructionFrame} />
				<Route path='/more-info' component={MoreInfo} />
				<Route path='/outlook' component={Outlook} />
				<Route path='/result' component={Result} />
				<Route path='/bind' component={BindNow} />
				<Route path='/thank-you' component={ThankYou} />
				<Route path='/quickquoteref' component={QuoteRef} />
				<Route path='/qqpl' component={PreloadRef} />
				<Route path='/quickquoteload' component={QuickQuoteLoad} />
				<Route path='/qqwidget' component={QQWidget} />
			</Switch>
		</ScrollToTop>
	);
}

export default App;
