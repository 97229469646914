import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setYear, setStories, chooseOccupancy, setReplacementCost, setCoverageA, setCoverageC } from '../../reducers/inputs';
// import { Select, InputLabel, MenuItem } from '@material-ui/core';

import Logo from '../common/Logo';

const MoreInfo = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const year_built = useSelector((state) => state.year_built);
	const num_floors = useSelector((state) => state.num_floors);
	const occupancy_q = useSelector((state) => state.occupancy_q);
	const rep_cost = useSelector((state) => state.rep_cost);
	const address = useSelector((state) => state.address);
	const city = useSelector((state) => state.city);
	const state = useSelector((state) => state.state);
	const zip = useSelector((state) => state.zip);
	const foundation = useSelector((state) => state.foundation_q);
	const building = useSelector((state) => state.building_q);
	const constr_frame = useSelector((state) => state.constr_frame);

	let basement = false;

	if (foundation.includes('base')) {
		basement = true;
	}

	if (!address) {
		history.push('./');
	} else if (!building) {
		history.push('./building');
	} else if (!foundation) {
		history.push('./foundation');
	} else if (!constr_frame) {
		history.push('./constr-frame');
	}

	const { register, handleSubmit, control } = useForm({
		defaultValues: {
			year_built: year_built,
			num_floors: num_floors.toString(),
			occupancy_q: occupancy_q,
			rep_cost: rep_cost,
		},
	});
	// const classes = useStyles();
	const onSubmit = async (data) => {
		let rep_cost = data.rep_cost;
		rep_cost = Number(rep_cost.toString().replace(/[^0-9.-]+/g, ''));
		const coverageA = Math.round(rep_cost * 0.8);
		const coverageC = Math.round(rep_cost * 0.4);

		dispatch(setYear(data.year_built));
		dispatch(setStories(data.num_floors));
		dispatch(chooseOccupancy(data.occupancy_q));
		dispatch(setReplacementCost(rep_cost));
		dispatch(setCoverageA(coverageA));
		dispatch(setCoverageC(coverageC));

		history.push('./outlook');
	};

	const date = new Date();
	const currentYear = date.getFullYear();

	return (
		<div className='bf-step-four'>
			<div className='bf-header'>
				<Logo />
				<p className='bf-title-address'>
					<i className='fas fa-map-marked'></i>
					{address}, {city}, {state} {zip}
				</p>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<h2 className='bf-title'>Please provide  a few more details about your property:</h2>
				<section className='bf-input'>
					<h3>Year Built</h3>
					<input name='year_built' ref={register({ required: true, min: 1800, max: currentYear })} placeholder='1994' />
				</section>
				<section className='bf-input'>
					<h3>Replacement Cost</h3>
					<div className='bf-dicription'>This is the best estimate for the replacement cost of the covered property and should not include the value of contents/personal property </div>
					<Controller value={rep_cost} name='rep_cost' control={control} as={<NumberFormat thousandSeparator={true} prefix={'$'} />} />
				</section>
				<section className='bf-input bf-radio'>
					<h3>How many stories is your home?</h3>
					<div className='bf-dicription'>Basements should be included when counting number of stories</div>

					<div className='row'>
						{!basement && (
							<div className='col m3 s6'>
								<input type='radio' id='control_1' name='num_floors' value='1' ref={register} />
								<label htmlFor='control_1'>
									<h4>1</h4>
								</label>
							</div>
						)}
						<div className='col m3 s6'>
							<input type='radio' id='control_2' name='num_floors' value='2' ref={register} />
							<label htmlFor='control_2'>
								<h4>2</h4>
							</label>
						</div>
						<div className='col m3 s6'>
							<input type='radio' id='control_3' name='num_floors' value='3' ref={register} />
							<label htmlFor='control_3'>
								<h4>3</h4>
							</label>
						</div>
						<div className='col m3 s6'>
							<input type='radio' id='control_4' name='num_floors' value='4' ref={register} />
							<label htmlFor='control_4'>
								<h4>4+</h4>
							</label>
						</div>
					</div>
				</section>
				<section className='bf-input bf-radio bf-radio-family'>
					<h3>Home Usage?</h3>
					<div className='row'>
						<div className='col m6 s12'>
							<input type='radio' id='control_5' name='occupancy_q' value='Primary Single Family' ref={register} />
							<label htmlFor='control_5'>
								<h4>
									Single Family<span>Primary Residence</span>
								</h4>
							</label>
						</div>
						<div className='col m6 s12'>
							<input type='radio' id='control_6' name='occupancy_q' value='NonPrimary Single Family' ref={register} />
							<label htmlFor='control_6'>
								<h4>
									Single Family<span>Non-Primary Residence</span>
								</h4>
							</label>
						</div>
					</div>
				</section>
				<div className='bf-button-wrapper'>
					<button className='bf-back' onClick={() => history.push('./constr-frame')}>
						<i className='fas fa-angle-left'></i> Back
					</button>
					<button className='bf-next'>
						Next <i className='fas fa-angle-right'></i>
					</button>
				</div>
			</form>
		</div>
	);
};

export default MoreInfo;
