import axios from 'axios';
import { sendBindEmail, sendQuoteEmail } from './email';
import { leadRecordStage, checkValue } from './helpers';

/**
 * This logging funciton sends the information to the node server to the bfleadrecord api. This function is designed to log major events that a client would trigger throughout the process. The major events are the different type of kickout events as well as the low potential lead and high potential lead events as a client works through the workflow.
 * The checkValue helper is used to check to see if there is a value. This function is called with some or all of the values below. If the value does not exist, an empty string needs to be passed along as a null or undefined value will mess with the API return. Therefore, we are using the checkValue helper function to just make sure there is a value and return an empty string if not.
 * @param {object} params - all the different parameters are passed to this function in the params object
 * @param {string} leadrecord_stage - this is a string indicating at what point this function was called
 * @returns There is a return, but it is not used. This is simply to log different events to the api.
 */
export const logLeadRecord = async (params, leadrecord_stage) => {
	let reqBody = {
		leadref_id: checkValue(params.leadref_id),
		lead_session_id: checkValue(params.client_id),
		quickquote_id: checkValue(params.quickquote_id),
		routelead_target_bfid: checkValue(params.routelead_info.agent_bfid),
		agent_contactemail: checkValue(params.routelead_info.agent_contactemail),
		lead_source: checkValue(params.routelead_info.lead_source),
		lead_crmload: checkValue(params.routelead_info.lead_crmload),
		leadrecord_stage: leadRecordStage(leadrecord_stage, params.routelead_info.lead_email_type, params.building_q),
		address: checkValue(params.address),
		city: checkValue(params.city),
		state: checkValue(params.state),
		zip: checkValue(params.zip),
		foundation_q: checkValue(params.foundation_q),
		num_floors: checkValue(params.num_floors),
		constr_frame: checkValue(params.constr_frame),
		year_built: checkValue(params.year_built),
		occupancy_q: checkValue(params.occupancy_q),
		rep_cost: checkValue(params.rep_cost),
		applicant: checkValue(params.applicant),
		appl_email: checkValue(params.appl_email),
		appl_phone: checkValue(params.appl_phone),
		eff_date_req: checkValue(params.eff_date_req),
		mortgage_related: checkValue(params.mortgage_related),
		current_flood_ins: checkValue(params.current_flood_ins),
		platform_quote_id: checkValue(params.platform_quote_id),
		deductible: checkValue(params.user_deductible),
		selected_tier: checkValue(params.selected_tier),
		bf_score: checkValue(params.bf_score),
		bf_score_cat: checkValue(params.bf_score_cat),
	};

	if (params.quote_set.length > 0) {
		reqBody = {
			...reqBody,
			ess_premium: checkValue(params.quote_set[0].tier_fees.premium_incl_fees_taxes),
			enh_premium: checkValue(params.quote_set[1].tier_fees.premium_incl_fees_taxes),
			eli_premium: checkValue(params.quote_set[2].tier_fees.premium_incl_fees_taxes),
		};
	}

	try {
		let response = await axios.post('/api/leadrecord', { params: reqBody });
		let responseData = response.data;

		let emailBody = {
			...params,
		};
		emailBody['timestamp'] = emailBody['timestamp'].toLocaleDateString("en-US")
		
		if (emailBody['routelead_info']['agent_contactphone'] && emailBody['routelead_info']['agent_contactphone'].length === 10) {
			emailBody['routelead_info']['agent_contactphone'] = emailBody['routelead_info']['agent_contactphone'].replace( /(\d{3})(\d{3})(\d{4})/, '($1) $2-$3' )
		}

		// here we are checking to see if the event is a low or high potential lead. If it is, we will call the sendQuoteEmail function for low potential leads and the sendBindEmail function for high potential leads. This will run the functions that communicate with the SendGrid API to send the different emails to the client and the agent if the agent is supposed to receive an email.
		if (leadrecord_stage === 'low_potential_lead') {
			emailBody['platform_quote_id'] = responseData.platform_quote_id
			await sendQuoteEmail(emailBody);
		} else if (leadrecord_stage === 'high_potential_lead') {
			await sendBindEmail(emailBody);
		}

		return responseData;
	} catch (error) {
		console.error(error.message);
	}
};
