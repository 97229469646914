/**
 * 
 * @param {string} param - string passed along to helper to display the correct flood risk meter image on the outlook page 
 * @returns 
 */
export function renderMeter(param) {
	switch (param) {
		case 'Very Low':
			return 'http://cdn.mcauto-images-production.sendgrid.net/70f38dda34834114/0096b6dc-327a-4d9c-a67a-acefca0a60d0/407x231.png';
		case 'Low':
			return 'http://cdn.mcauto-images-production.sendgrid.net/70f38dda34834114/c249fc15-9f17-4adb-85e3-8f11718bccb4/407x231.png';
		case 'Moderate':
			return 'http://cdn.mcauto-images-production.sendgrid.net/70f38dda34834114/d20e49b5-125a-47c8-8e61-8e11c9205489/407x231.png';
		case 'High':
			return 'http://cdn.mcauto-images-production.sendgrid.net/70f38dda34834114/265e0066-469d-42c5-8c2b-30c236b381ae/409x229.png';
		case 'Very High':
			return 'http://cdn.mcauto-images-production.sendgrid.net/70f38dda34834114/99c32393-a550-47e4-b6f5-121a17f32ede/407x231.png';
		default:
			return 'http://cdn.mcauto-images-production.sendgrid.net/70f38dda34834114/d20e49b5-125a-47c8-8e61-8e11c9205489/407x231.png';
	}
}

/**
 * 
 * @param {*} value - Helper function used to check to see if there is a value. Some functions and api calls have missing values depeding on what stage we are at and a null or undefeined can cause problems, so we are just checking for a value and returning an empty string if there is no value. 
 * @returns 
 */
export function checkValue(value) {
	if (value) {
		return value;
	} else {
		return '';
	}
}

/**
 * 
 * @param {string} reason - this helper function takes in the reason for a kickout event and returns a description that is the first sentence displayed on the kickout page. 
 * @returns 
 */
export function reasonDescription(reason) {
	switch (reason) {
		case 'state':
			return 'Beyond Floods coverage is not currently available in your location.';
		case 'address':
			return 'We were unable to determine your address from the information provided. Please try typing in a new address or using the manual address form.';
		case 'building':
			return 'Beyond Floods does not currently cover your type of building.';
		case 'outlook':
			return 'There seems to be a problem with the inputs you have chosen.';
		case 'quote':
			return 'We were not able to successfully run a quote for you at this time.';
		default:
			return reason;
	}
}

/**
 * 
 * @param {string} leadrecord_stage - this is the tage that the funcion is called at 
 * @param {string} lead_email_type  - this is used to know what numper to affix to the end of the leadREcordStage
 * @param {string} building_type  - this is the building type used for the kickout stage to affix the correct number to the correct building reference
 * @returns This helper funciton returns a leadRecordStage variable that is used in the leadRecord.js file when logging a lead record event either for a kickout or a successful completion of a low or high potential lead.
 */
export function leadRecordStage(leadrecord_stage, lead_email_type, building_type) {
	let leadRecordStage;

	switch (leadrecord_stage) {
		case 'kickout_building':
			leadRecordStage = 'KBL';
			break;
		case 'kickout_building_form':
			leadRecordStage = 'KBF';
			break;
		case 'kickout_state':
			leadRecordStage = 'KST01';
			break;
		case 'kickout_state_form':
			leadRecordStage = 'KSF01';
			break;
		case 'kickout_outlook':
			leadRecordStage = 'KOU01';
			break;
		case 'kickout_outlook_form':
			leadRecordStage = 'KOF01';
			break;
		case 'kickout_quote':
			leadRecordStage = 'KQU01';
			break;
		case 'kickout_address':
			leadRecordStage = 'KAD01';
			break;
		case 'kickout_address_form':
			leadRecordStage = 'KAF01';
			break;
		case 'low_potential_lead':
			leadRecordStage = 'LPL';
			break;
		case 'high_potential_lead':
			leadRecordStage = 'HPL';
			break;
		default:
			leadRecordStage = 'DFT';
	}

	// This if statement is to affix the correct number to the end of the lead record stage for a building kickout in reference to what kind of building it is. This allows the logging to track the different type of building kickouts.
	if (leadrecord_stage.includes('_building')) {
		switch (building_type) {
			case 'condo':
				leadRecordStage += '01';
				break;
			case 'multi_family':
				leadRecordStage += '02';
				break;
			case 'manufactured':
				leadRecordStage += '03';
				break;
			case 'commercial':
				leadRecordStage += '04';
				break;
			case 'other':
				leadRecordStage += '05';
				break;
			default:
				leadRecordStage += '05';
		}
	}

	// This if statement is for low and high potential leads to affix the correct number to the end of the lead record stage depeding on what the lead_email_type is.
	if (leadrecord_stage.includes('_potential_lead')) {
		switch (lead_email_type) {
			case 'EMQREF01':
			case 'NOEMAL01':
			case 'NOEMAL02':
				leadRecordStage += '01';
				break;
			case 'EMLINK01':
				leadRecordStage += '02';
				break;
			default:
				leadRecordStage += '01';
		}
	}

	return leadRecordStage;
}

/**
 * Helper function used to extract individual components from address array returned from Google geocodeByAddress
 * @param {components} list of components from Google Places
 * @param {type} type of component looking for
 * @returns specific component from array that matches type input
 */
export function extractFromAdress(components, type) {
	for (var i = 0; i < components.length; i++) for (var j = 0; j < components[i].types.length; j++) if (components[i].types[j] === type) return components[i].short_name;
	return '';
}

/**
 * 
 * @param {string} phoneNumberString - phone number in string format. Uusually just 10 digits in a row 
 * @returns This helper is just returning a formatted phone number in the format of (xxx) xxx-xxxx
 */
export function formatPhoneNumber(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		const newNumb = '(' + match[1] + ') ' + match[2] + '-' + match[3];
		return newNumb;
	}
	return null;
}

/**
 * 
 * @param {number} rep_cost - the replacement cost as a number 
 * @returns This is returning a number replacement cost as a formatted dollar string. $x,xxx.xx
 */
export function formatRepCost(rep_cost) {
	// Create our number formatter.
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	return formatter.format(rep_cost);
}

/**
 * 
 * @param {string} foundation - foundation_q type is the input 
 * @returns This is returning a better description of the foundation type for use in the emails to clients and agents
 */
export function setFoundationType(foundation) {
	switch (foundation) {
		case 'slab':
			return 'Slab';
		case 'crawl_open':
			return 'Open Crawl Space';
		case 'elev_free':
			return 'Elevated on Peirs / Posts / Piles';
		case 'garage_open':
			return 'Home on top of Garage';
		case 'base_below':
			return 'Basement Fully Below Grade';
		case 'base_partbelow':
			return 'Basement Partially Below Grade';
		case 'base_walkout':
			return 'Walkout Basement';
		default:
			return 'Slab';
	}
}

/**
 * 
 * @param {string} leadref_ID - current leadref_ID
 * @returns True if leadref_ID is a natgen leadref
 */
export function isNatGenLeadref(leadref_ID) {
	const natGenLeadrefs= [
		'7vqbsq18af3',
		'lqnj107u4g3',
		'n6ts92wxv24',
		'ftoj3fngg5z',
		'g0pz7uj8bqi',
		'j61bkp9ioqk',
		'wg61dx1tm6b',
		'dxzf6isi2lz',
		'cobuh0owqyt',
	];

	return natGenLeadrefs.includes(leadref_ID)
}
