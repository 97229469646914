import React from "react";
import { useSelector } from "react-redux";
import Logo from "../common/Logo";

import AgentDetails from '../common/AgentDetails';

import "react-datepicker/dist/react-datepicker.css";

const ThankYou = () => {
  // eslint-disable-next-line
  const allData = useSelector((state) => state);
  const routeLeadInfo = allData.routelead_info;

  return (
    <div className="bf-bind-now">
      <div className="bf-header">
        <Logo />
        <p className="bf-title-address">
          <i className="fas fa-map-marked"></i>
          {allData.address}, {allData.city}, {allData.state} {allData.zip}
        </p>
      </div>
      <div className="bf-thank-you-wrapper">
        <h4 className="bf-title">Thank you for your request</h4>
        <p className="bf-p">
          An email with details has been sent to one of our knowledgeable agent
          partners. They will be reaching out to you soon to discuss your
          request, answer any questions you may have about your flood insurance
          options, and assist you in the purchase process. They will be able to
          offer you higher limits and additional options like deductibles,
          swimming pool coverage, basement dwelling and contents coverage, loss
          of use coverage, tree and shrub coverage, etc.
        </p>
      </div>
      {routeLeadInfo.show_agent_info && (
        <div className="col l4 s12 center-align-on-med-and-down">
          <AgentDetails routelead_info={routeLeadInfo} />
        </div>
      )}
    </div>
  );
};

export default ThankYou;
