import axios from 'axios';
import { checkValue } from './helpers';

/**
 * This function calls the routelead api through node to fetch the agent and agency data to use for a client. A valid leadref_id will return the associated route lead for that leadref campaign. If no leadref_id is present, a routelead will be assigned based on geography.
 * @param {object} values
 * Expected Values: leadref_id, address, city, state, zip, lat, long, quickquote_id, rep_cost
 * @returns bfroutelead object
 */
export const fetchRouteLead = async (values) => {
	let reqBody = {
		quickquote_id: checkValue(values.quickquote_id),
		leadref_id: checkValue(values.leadref_id),
		address: checkValue(values.address),
		city: checkValue(values.city),
		state: checkValue(values.state),
		zip: checkValue(values.zip),
		rep_cost: checkValue(values.rep_cost),
	};

	if (values.geoc_lat && values.geoc_lon) {
		reqBody = {
			...reqBody,
			geoc_lat: checkValue(values.geoc_lat),
			geoc_lon: checkValue(values.geoc_lon),
		};
	}

	try {
		let response = await axios.post('/api/routelead', { params: reqBody });
		return response;
	} catch (error) {
		console.error(error.message);
	}
};
