/**
 * The MarketingComDisclaimer component is used to generate the HTML snippet used to display the disclaimer regarding marketing communications.
 * @param {object} props - The props object contains information on the button name.
 * @returns Marketing Communications Disclaimer HTML snippet
 */

 export default function MarketingComDisclaimer(props) {

    return (
        <div className='bf-disclaimer'>
            By clicking the “Proceed to Quote” button this constitutes my written consent to receive marketing communications and related calls and/or
            text messages from Allstate and its affiliates including those made via autodialer, automated technology and/or prerecorded or artificial
            voice message at the above phone number and email, even if my number is registered on a Do Not Call Registry or previously unsubscribed via email.
            I affirm that I am the regular user of the number provided.  I understand that message and data rates may apply, that my consent is not a condition of purchase
            and that I can revoke my consent at any time. 
        </div>
    )
}
