import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { chooseAddress, chooseCity, chooseState, chooseZip, setLeadRefId, setRouteLeadInfo } from '../../reducers/inputs';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';

import Logo from '../common/Logo';
import Unavailable from '../unavailable/Unavailable';
import { fetchRouteLead } from '../../actions/routeLead';
import { gaEvent } from '../../actions/ganalytics';

/**
 * The Widget functional component is the page that the embedded widget is redirected to. The embedded wideget will link a potential client to the website /qqwidget with an address, city, state, zip, and leadrefID. This function will take in that information, store the information in the reducers, set the cookie to the leadrefID and then redirect to the foundation page for the potential client to continue the workflow.
 * @returns 
 */
const Widget = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const values = queryString.parse(search);
	// eslint-disable-next-line
	const [cookies, setCookie] = useCookies(['leadref_id']);
	const [availability, setAvailability] = useState(true);
	const [availabilityReason, setAvailabilityReason] = useState('');
	const [routeLead, setRouteLead] = useState();

	/**
	 * List of states that we offer BeyondFloods coverage in
	 */
	const availableStates = [
		'AL',
		'AZ',
		'CA',
		'CO',
		'CT',
		'FL',
		'GA',
		'IA',
		'ID',
		'IL',
		'IN',
		'LA',
		'MA',
		'MD',
		'ME',
		'MI',
		'MN',
		'MS',
		'NC',
		'NH',
		'NJ',
		'NM',
		'OH',
		'OR',
		'PA',
		'RI',
		'SC',
		'TN',
		'TX',
		'VA',
		'VT',
		'WA',
		'WV',
	];

	/*
	.com/qqwidget?address=${address}&city=${city}&state=${state}&zip=${zip}&leadref=${leadref}
	Expected values
	address = street address
	city = city
	state = state
	zip = zip code
	leadref = leadref ID 
	*/

	const fetchQuickQuoteInfo = () => {
		dispatch(chooseAddress(values.address));
		dispatch(chooseCity(values.city));
		dispatch(chooseState(values.state));
		dispatch(chooseZip(values.zip));
		dispatch(setLeadRefId(values.leadref));

		let params = {
			leadref_id: values.leadref,
			address: values.address,
			city: values.city,
			state: values.state,
			zip: values.zip,
		}

		// Check if the state is supported
		if (!availableStates.includes(values.state)) {
			doKickout(params, 'state');
			return;
		}

		// Check that all 4 address components are there
		if(values.address && values.city && values.state && values.zip) {
			setTimeout(() => {
				setCookie('leadref_id', values.leadref, { path: '/' });
				history.push('/foundation');
			}, 400);
		} else {

			// malformed address kickout
			doKickout(params, 'address');
		}
		
	};

	const doKickout = async (params, reason) => {
		let gaParams = {
			action: '',
			category: '',
			label: '',
		}

		if (reason === 'address') {
			gaParams = {
				action: 'kickout_address',
				category: 'kickout_event',
				label: 'Kickout for Bad Address',
			}
		}
		if (reason === 'state') {
			gaParams = {
				action: 'kickout_state',
				category: 'kickout_event',
				label: 'Kickout for State Coverage',
			}
		}

		const routeLeadFetch = await fetchRouteLead(params);
		const routeLeadData = routeLeadFetch.data;
		gaEvent(gaParams);

		dispatch(setRouteLeadInfo(routeLeadData));
		setRouteLead(routeLeadData.routelead_info);
		setAvailabilityReason(reason);
		setAvailability(false);
	}

	useEffect(() => {
		fetchQuickQuoteInfo();
		// eslint-disable-next-line
	}, []);
	

	return (
		<div className='bf-widget'>
			<Logo />
			{!availability && <Unavailable reason={availabilityReason} contact={routeLead} form={true} />}
		</div>
	);
};

export default Widget;
