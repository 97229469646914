import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import AgentDetails from '../common/AgentDetails';
import LoadingSpinner from "../common/loadingSpinner";
import Logo from "../common/Logo";
import { isNatGenLeadref } from '../../actions/helpers';
import { natgenFloodlightQuoteComplete } from '../../actions/natgenGoogleFloodlight/natgenFloodlightQuoteComplete';
import { setQuoteSelected } from "../../reducers/inputs";

const Result = () => {
  const currentState = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [quickQuoteBestFit, setQuickQuoteBestFit] = useState("");
  const [quickQuoteSet, setQuickQuoteSet] = useState([]);
  const [referral, setReferral] = useState(false);
  const routeLeadInfo = currentState.routelead_info;
  const [showLoadSpin, setShowLoadSpin] = useState(true);

  if (!currentState.address) {
    history.push("./");
  } else if (!currentState.building_q) {
    history.push("./building");
  } else if (!currentState.foundation_q) {
    history.push("./foundation");
  } else if (!currentState.constr_frame) {
    history.push("./constr-frame");
  } else if (
    !currentState.rep_cost ||
    !currentState.year_built ||
    !currentState.occupancy_q ||
    !currentState.num_floors
  ) {
    history.push("./more-info");
  } else if (!currentState.quote_set) {
    history.push("./outlook");
  }

  useEffect(() => {
    const quotes = currentState.quote_set;
    const statusList = currentState.bfquickquote_status_list;
    if (statusList[0].bfquickquote_status === "Q002") {
      setReferral(true);
    }
    const quoteSet = quotes;
    setQuickQuoteSet(quoteSet);
    setShowLoadSpin(false);
    quoteSet.forEach((item) => {
      if (item.quote_bestfit) {
        setQuickQuoteBestFit(item.quote_tier);
      }
    });

    // Only trigger in live for NatGen leadrefs
    if (isNatGenLeadref(currentState.leadref_id) && process.env.REACT_APP_ENV === "live") {
      natgenFloodlightQuoteComplete();
    }
  }, [currentState]); // Or [] if effect doesn't need props or state

  const bfSelect = (e) => {
    dispatch(setQuoteSelected(e.target.value));
    history.push("./bind");
  };

  return (
    <div className='bf-contents'>
			{showLoadSpin && <LoadingSpinner />}
      <div className="bf-result-container" {...showLoadSpin && {hidden: true}}>
        <div className="bf-header">
          <Logo />
          <p className="bf-title-address">
            <i className="fas fa-map-marked"></i>
            {currentState.address}, {currentState.city}, {currentState.state}{" "}
            {currentState.zip}
          </p>
        </div>
        {routeLeadInfo.show_agent_info && (
          <div className="row">
            <div className="col l8 s12 left-align center-align-on-med-and-down">
              <h2 className="bf-title">
                Coverage Options Available*{" "}
                <span>Underwritten by National General Member Companies</span>
              </h2>
              <p className="bf-thanks">
                Thank you for quoting with Beyond Floods. An email with the
                information below and your personalized Flood Outlook Score has
                been sent to {currentState.appl_email}.<br />
                <br />
                We look forward to talking with you and will call you shortly.
              </p>
            </div>
            <div className="col l4 s12 right-align center-align-on-med-and-down">
              <AgentDetails routelead_info={routeLeadInfo} />
            </div>
          </div>
        )}
        {!routeLeadInfo.show_agent_info && (
          <div className="row">
            <div className="col l8 offset-l2 s12 center-align">
              <h2 className="bf-title">
                Coverage Options Available*{" "}
                <span>Underwritten by National General Member Companies</span>
              </h2>
              <p className="bf-thanks left-align center-align-on-med-and-down">
                Thank you for quoting with Beyond Floods. An email with the
                information below and your personalized Flood Outlook Score has
                been sent to {currentState.appl_email}. We look forward to talking
                with you and will call you shortly.
              </p>
            </div>
          </div>
        )}
        {referral && (
          <div className="bf-quote-referral">
            <h2 className="bf-title">
              ***Quote May be Subject to Underwriting Approval***
            </h2>
          </div>
        )}
        <div className="bf-quote-wrapper">
          {quickQuoteSet.map((item, index) => {
            let bestFit;
            let bfBestFitBanner;
            let quoteTier = item.quote_tier;
            if (quickQuoteBestFit === quoteTier) {
              bestFit = "bf-best-fit";
              bfBestFitBanner = (
                <div className="bf-best-fit-banner">
                  <h4>Best Fit</h4>
                </div>
              );
            } else if (item.quote_eligible) {
              bestFit = "";
              bfBestFitBanner = (
                <div className="bf-best-fit-banner">
                  <h4>Eligible</h4>
                </div>
              );
            } else {
              bestFit = "";
              bfBestFitBanner = (
                <div className="bf-best-fit-banner">
                  <h4>Not Eligibile</h4>
                </div>
              );
            }
            if (item.quote_eligible) {
              return (
                <div
                  key={index}
                  id={`bf-${quoteTier.toLowerCase()}`}
                  className={`bf-quote-block bf-${item.quote_tier.toLowerCase()} ${bestFit}`}
                >
                  <div className="bf-quote-header">
                    {bfBestFitBanner}
                    <h4>{item.quote_tier}</h4>
                    <h5>
                      ${item.tier_fees.premium_incl_fees_taxes.toFixed(2)} **
                    </h5>
                    <button
                      value={quoteTier}
                      onClick={bfSelect}
                      className="bf-select"
                    >
                      Select Tier
                    </button>
                    <p>{item.quote_desc}</p>
                  </div>
                  <div className="bf-quote-body">
                    {/* <p className='bf-warn'>Quotes shown are subject to referral prior to binding</p> */}
                    <div>
                      <strong>
                        <NumberFormat
                          value={item.deductible}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </strong>
                      <span>Deductible</span>
                    </div>
                    <div>
                      <strong>
                        <NumberFormat
                          value={item.cov_a_bldg}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </strong>
                      <span>Dwelling Limit</span>
                    </div>
                    <div>
                      <strong>
                        <NumberFormat
                          value={item.cov_b_other_bldg}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </strong>
                      <span>Other Building Limit</span>
                    </div>
                    <div>
                      <strong>
                        <NumberFormat
                          value={item.cov_c_cont}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </strong>
                      <span>Content Limit</span>
                    </div>
                    <div>
                      <strong>
                        <NumberFormat
                          value={item.cov_f_lossofuse}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </strong>
                      <span>Loss of Use</span>
                    </div>
                    <div>
                      <strong>
                        <NumberFormat
                          value={item.cov_c_special}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </strong>
                      <span>Special Limits Cap</span>
                    </div>
                    <div>
                      <strong>
                        <NumberFormat
                          value={item.cov_e_icc}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </strong>
                      <span>Increased Cost of Compliance</span>
                    </div>
                    <div className="bf-extras">
                      {item.cov_d_debris > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_debris}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          Debris Removal
                        </div>
                      )}
                      {item.cov_d_lossavoid > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_lossavoid}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          Loss Avoidance Measures
                        </div>
                      )}
                      {item.cov_d_propremove > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_propremove}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          Property Removed to Safety
                        </div>
                      )}
                      {item.cov_d_lossassess > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_lossassess}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          Loss Assessment
                        </div>
                      )}
                      {item.cov_d_firedeptserv > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_firedeptserv}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          Fire Dept. Service Charge
                        </div>
                      )}
                      {item.cov_d_deckramp > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_deckramp}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          Deck & Handicap Ramp Coverage
                        </div>
                      )}
                      {item.cov_d_laword > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_laword}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          of Dwelling Limit - Law Ordinance Endorsement
                        </div>
                      )}
                      {item.cov_d_pool > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_pool}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          Pool Clean-up / Repair
                        </div>
                      )}
                      {item.cov_d_trees > 0 && (
                        <div>
                          <strong>
                            <NumberFormat
                              value={item.cov_d_trees}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                          Trees, Shrubs & Other Plants
                        </div>
                      )}
                      <p>Other Coverages</p>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  id={`bf-${quoteTier}`}
                  className={`bf-quote-block ${item.quote_tier.toLowerCase()} disabled`}
                >
                  <div className="bf-quote-header">
                    {bfBestFitBanner}
                    <h4>{item.quote_tier}</h4>
                    <h5>$N/A ***</h5>
                    <h6>Your Property is Ineligible for this Product.</h6>
                  </div>
                  <div className="bf-quote-body">
                    {/* <p className='bf-warn'>Quotes shown are subject to referral prior to binding</p> */}
                    <div>
                      <strong>$N/A</strong>
                      <span>Deductible</span>
                    </div>
                    <div>
                      <strong>$N/A</strong>
                      <span>Dwelling Limit</span>
                    </div>
                    <div>
                      <strong>$N/A</strong>
                      <span>Other Building Limit</span>
                    </div>
                    <div>
                      <strong>$N/A</strong>
                      <span>Content Limit</span>
                    </div>
                    <div>
                      <strong>$N/A</strong>
                      <span>Loss of Use</span>
                    </div>
                    <div>
                      <strong>$N/A</strong>
                      <span>Special Limits Cap</span>
                    </div>
                    <div>
                      <strong>$N/A</strong>
                      <span>Increased Cost of Compliance</span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="bf-button-wrapper">
          <Link className="bf-button-wrapper" to="/foundation">
            Modify Quote
          </Link>
          {/* 
            <Link className='bf-button-wrapper' to='/'>
            Start over
          </Link> 
          */}
        </div>
        <div className="bf-disclaimer-wrapper">
          <p className="bf-disclaimer">
            • *DISCLAIMER: The policy premium estimates contained in this website
            is for illustrative purposes only and coverage under any private flood
            insurance policy is expressly subject to the conditions, restrictions,
            limitations, exclusions and terms of the policy documentation issued
            by the insurer. Availability of this program is subject to each state'
            s approval and coverage may vary by state. All such information on
            this site is presented without any representation, guaranty, or
            warranty whatsoever regarding the accuracy, relevance, or completeness
            of the information. Private flood insurance policies are underwritten
            by Direct General Insurance Company, National General Premier
            Insurance Company, Agent Alliance Insurance Company or other wholly
            owned AM Best A- Excellent Rated subsidiaries of National General
            Insurance Company. This product is not affiliated with the National
            Flood insurance Program.
          </p>
          <p className="bf-disclaimer">
            **Applicable MGA fee, inspection fees, and other state fees and taxes
            included in indicative premium. Other nominal fees associated with
            installment plans will apply if payment plans other than 'Annual'
            selected.
          </p>
        </div>

        {/* // NatGen Floodlight */}
        <noscript>
          { /* eslint-disable no-template-curly-in-string */ }
          <img src="https://ad.doubleclick.net/ddm/activity/src=1886972;type=natg694;cat=ngbey00;u1=1;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?" width="1" height="1" alt=""/>
          { /* eslint-enable no-template-curly-in-string */ }
        </noscript>
      </div>
    </div>
  );
};

export default Result;
