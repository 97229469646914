import axios from 'axios';

/**
 * This function calls the quick quote info api with an object of values. The return is the information associated with a quick quote ID. This is useful and triggered with the modify quote link in the client email and in the QuoteRef.jsx functional component.
 * @param {object} values 
 * @returns Quick Quote Info
 */
export const fetchQuickQuoteInfo = async (values) => {
	try {
		let response = await axios.post('/api/quickquote-info', { params: values });
		let quoteInfo = response;
		return quoteInfo;
	} catch (error) {
		console.error(error.message);
	}
};

/**
 * This function calls the quick quote API through the node server and returns the successful quick quote data. This function is called in the Outlook.jsx functional component
 * @param {object} testBody 
 * @returns returns the quick quote
 */
export const fetchQuickQuote = async (testBody) => {
	try {
		let response = await axios.post('/api/quick-quote', { params: testBody });
		const quoteData = response.data;

		return quoteData;
	} catch (error) {
		console.error(error.message);
	}
};
